import * as React from "react"
import { SVGProps } from "react"

const CardIconThree = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="150"
		height="150"
		viewBox="0 0 150 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M59.6423 59.9857V74.5594C59.6423 78.4285 66.5186 81.565 75.001 81.565C83.4834 81.565 90.3597 78.4285 90.3597 74.5594L90.3595 74.522L90.3597 59.9857M48.9141 54.5922L75.001 42.4346L101.088 54.5922L75.001 66.7499L48.9141 54.5922Z"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<rect
			x="25"
			y="27"
			width="100"
			height="70"
			rx="4"
			stroke="#3021E5"
			strokeWidth="3"
		/>
		<path
			d="M42.5 123L107.5 123"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M59.7246 97L59.7246 123"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M90.2754 104.277L90.2754 123"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>
)

export default CardIconThree
