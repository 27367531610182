import React, { useState } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import TakeMyMoney from "./assets/TakeMyMoney"
import TakeMyMoney2 from "./assets/TakeMyMoney2"
import PhoneBackdropBubble from "./assets/PhoneBackdropBubble"
import data from "./data"
import ConsultationCtaFormA from "../../../components/_V2/ConsultationCtaFormA"
import ConsultationCtaFormB from "../../../components/_V2/ConsultationCtaFormB"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";
import {getData} from "../../index/CtaForm/data";



export enum CTA_FROM_THEMES {
	DEFAULT = "DEFAULT",
	CYAN = "CYAN",
	FUCHSIA = "FUCHSIA",
}

interface Props {
	className?: string
	isWhiteBackground?: boolean
	theme?: CTA_FROM_THEMES
}

export default function CtaForm(props: Props) {
	const isWhiteBackground = props.isWhiteBackground || false
	const localizationContext = useLocalizationContext();

	const theme = props.theme || CTA_FROM_THEMES.DEFAULT
	const isCyanTheme = theme === CTA_FROM_THEMES.CYAN || false
	const isFuchsiaTheme = theme === CTA_FROM_THEMES.FUCHSIA || false

	const [isModalOpen, setIsModalOpen] = useState(false)

	const handleModalStatus = () => {
		setIsModalOpen(!isModalOpen)
	}

	const type = "b"

	return (
		<>
			<section
				className={classNames(
					styles.section,
					props.className,
					{ [styles.sectionThemeCyan]: isCyanTheme },
					{ [styles.sectionThemeFuchsia]: isFuchsiaTheme }
				)}
			>
				<div
					className={classNames(styles.content, {
						[styles.contentWhite]: isWhiteBackground,
					})}
				>
					<div className={styles.titleWrapper}>
						<p className={styles.subTitle}>{getData(localizationContext.locale).subTitle}</p>
						<div className={styles.illustration}>
							{<TakeMyMoney2 className={styles.illustrationImage} />}

							<h2 className={styles.title}>{getData(localizationContext.locale).title}</h2>
						</div>
					</div>
					<div className={styles.buttonsWrapper}>
						<a
							className={styles.contactPhoneNumberButton}
							href={`tel:${getData(localizationContext.locale).phone.value}`}
						>
							<PhoneBackdropBubble
								className={styles.phoneBackdrop}
								fill={
									isCyanTheme
										? "#EFFAFD"
										: isFuchsiaTheme
										? "#FAF5FF"
										: "#F7F5FF"
								}
							/>
							<span className={styles.phoneNumberButtonText}>
								{getData(localizationContext.locale).phone.text}
							</span>
						</a>
						<a
							className={styles.ctaButton}
							onClick={handleModalStatus}
							href={"#popup"}
						>
							{getData(localizationContext.locale).ctaButtonText}
						</a>
					</div>
				</div>
			</section>

			<ConsultationCtaFormA isOpen={isModalOpen} onClose={handleModalStatus} />

			{/*{ type === 'a' ? (*/}
			{/*    <ConsultationCtaFormA*/}
			{/*        isOpen={isModalOpen}*/}
			{/*        onClose={handleModalStatus}*/}
			{/*    />*/}
			{/*) : (*/}
			{/*    <ConsultationCtaFormB*/}
			{/*        isOpen={isModalOpen}*/}
			{/*        onClose={handleModalStatus}*/}
			{/*    />*/}
			{/*)}*/}
		</>
	)
}
