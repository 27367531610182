import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { pagesLinks } from "./links"
import CoffeeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CoffeeIcon"
import RestaurantIcon from "../../../components/_V2/BusinessTypes/assets/svg/RestaurantIcon"
import componentStyles from "../../../components/_V2/BusinessTypes/styles.module.scss"
import {
	BUBBLES_TYPES,
	BUSINESS_TYPES,
	Data,
} from "../../../components/_V2/BusinessTypes/types"
import classNames from "classnames"

import {Locales} from "../../../../localization/types";
import BarIcon from "../../../components/_V2/BusinessTypes/assets/svg/BarIcon";
import CafeIcon from "../../../components/_V2/BusinessTypes/assets/svg/CafeIcon";
import FoodtrackIcon from "../../../components/_V2/BusinessTypes/assets/svg/FoodtrackIcon";
import MultiformatIcon from "../../../components/_V2/BusinessTypes/assets/svg/MultiformatIcon";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			title: "Решения на основе данных",
			subTitle: "системный подход",
			readMore: "Смотреть интервью",
			theme: "blueSky",
			changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
					icon: CoffeeIcon, // Нужно заменить данные
					name: "Владимир Михеев, основатель компании DO.BRO Coffee",
					cardTitle: "Решения на основе данных", // Для changeTitles
					cardSubtitle: "системный подход", // Для changeTitles
					details: (
						<>
							Автоматизация помогает нам думать системно. Там много <br/>
							отчётов — если погрузиться досконально в аналитику, <br/>
							ты можешь из неё выкручивать полезные инсайты. <br/>
							Используем это для контроля новых решений.
						</>
					),
					detailsTablet:
						"Автоматизация помогает нам думать системно. Там много отчётов — если погрузиться досконально в аналитику, ты можешь из неё выкручивать полезные инсайты. Используем это для контроля новых решений.",
					detailsMobile:
						"Автоматизация помогает нам думать системно. Там много отчётов — если погрузиться досконально в аналитику, ты можешь из неё выкручивать полезные инсайты. Используем это для контроля новых решений.",
					photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/do-bro.png"
							alt="Логотип компании DO.BRO Coffee"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropDobroCoffe
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/dobro-coffe.png"
							alt="Владимир Михеев"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					// link: {
					// 	href: "javascript:void(0)",
					// 	text: "Смотреть интервью",
					// 	useGatsbyLink: true,
					// },
				},
				{
					type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
					tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
					icon: RestaurantIcon, // Нужно заменить данные
					bubbleType: BUBBLES_TYPES.SECONDARY,
					cardTitle: "Расставляй сети, как удобно", // Для changeTitles
					cardSubtitle: "опыт наших клиентов", // Для changeTitles
					name: "Анастасия Филлипова, основатель проекта «Шоу'рма»",
					details: (
						<>
							Для нашей сети было очень важно перейти на систему
							<br />
							с российскими корнями, но с опционалом не хуже
							<br />
							передовых иностранных аналогов и Quick Resto оправдали
							<br />
							наши ожидания.
						</>
					),
					detailsTablet:
						"Для нашей сети было очень важно перейти на систему с российскими корнями, но с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
					detailsMobile:
						"Для нашей сети было очень важно перейти на систему с российскими корнями, но с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
					photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/korizza.png"
							alt="Логотип проекта «Шоу'рма»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropShowRoom
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/show-room.png"
							alt="Анастасия Филлипова"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					// link: {
					// 	href: "javascript:void(0)",
					// 	text: "Смотреть интервью",
					// 	useGatsbyLink: true,
					// },
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: "Преодолей барьеры и открой возможности",
			titleClass: componentStyles.title__kz,
			subTitle: "создан для всех типов бизнеса",
			readMore: "Смотреть интервью",
			theme: "blueSky",
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP,
					tabName: pagesLinks.businessTypes.coffeehouse.text,
					icon: CoffeeIcon,
					name: "Рауан Омар, \nоснователь кофейни «Korizza»",
					nameClass: componentStyles.businessName__kz,
					details:
						"Используем инструменты Quick Resto для оптимизации \nпроцессов и анализа продаж, чтобы развивать бизнес.",
					detailsTablet:
						"Используем инструменты Quick Resto для оптимизации процессов и анализа продаж, чтобы развивать бизнес.",
					detailsMobile:
						"Используем инструменты Quick Resto для оптимизации процессов и анализа продаж, чтобы развивать бизнес.",
					photoCaption: "«Девиз: \nКофе объединяет людей»",
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/korizza.png"
							alt="Логотип кофейни «Korizza»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={componentStyles.businessLogo__kz}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/korizza.jpeg"
							alt="Рауан Омар, основатель кофейни «Korizza»"
							// breakpoints={[424, 740, 920, 524]}
							// sizes="(max-width: 500px) 424px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 524px"
							objectFit="cover"
							placeholder="blurred"
							quality={100}
						/>
					),

				},
				{
					type: BUSINESS_TYPES.RESTAURANT,
					tabName: pagesLinks.businessTypes.restaurant.text,
					icon: RestaurantIcon,
					name: "Константин Кудобаев, \nсооснователь частного бара «Сезоны»",
					photoCaption: "«Чувствую себя героем игры, каждый день новые задания»",
					bubbleType: BUBBLES_TYPES.SECONDARY,
					details: (
						<>
							В&nbsp;первую очередь нас привлекло удобство интерфейса&nbsp;&mdash;
							это&nbsp;айфон на&nbsp;рынке систем автоматизации.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/seasons.png"
							alt="Логотип ресторана «Сезоны»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropSeasons
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/seasons.jpg"
							alt="Константин Кудобаев, сооснователь частного бара «Сезоны»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/sezony/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
				{
					type: BUSINESS_TYPES.BAR,
					tabName: pagesLinks.businessTypes.bar.text,
					icon: BarIcon,
					name:
						"Куаныш Нурахов, \nсооснователь изакая-бар «Tang»",
					photoCaption: (
						<div className={componentStyles.businessPhotoCaptionContent_tertiary}>
							«Среди тех, <br />
							кто любит лапшу <br />
							нет плохих людей!»
						</div>
					),
					bubbleType: BUBBLES_TYPES.LINEAR,
					details: (
						<>
							Quick Resto оптимизирует все процессы и&nbsp;помогает контролировать движение денежных средств
						</>
					),
					detailsMobile: (
						<>
							Используем инструменты Quick Resto для оптимизации процессов и&nbsp;анализа продаж, чтобы развивать бизнес.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/tang.png"
							alt="Логотип сети винотек и баров «Первый нос»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo__kz_bar
								// componentStyles.businessMainImageCropBar
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/tang.jpeg"
							alt="Куаныш Нурахов - сооснователь изакая-бар «Tang»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.CAFE,
					tabName: pagesLinks.businessTypes.cafe.text,
					icon: CafeIcon,
					name: "Анастасия и Андрей, \nсооснователи «О, мой Боул!»",
					photoCaption: "«Общепит не только про еду, но и про эмоции»",
					bubbleType: BUBBLES_TYPES.QUATERNARY,
					details: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые
							нам&nbsp;раньше требовался отдельный человек.
						</>
					),
					detailsMobile: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые нам
							раньше требовался отдельный человек.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/oh-my-bowl.png"
							alt="Логотип Oh My Bowl"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropOhMyBowl
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/oh-my-bowl.jpg"
							alt="Анастасия и Андрей, сооснователи Oh My Bowl"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/ohmybowl/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
				{
					type: BUSINESS_TYPES.FOODTRACK,
					tabName: pagesLinks.businessTypes.foodtruck.text,
					icon: FoodtrackIcon,
					name: "Владимир Иванюгин, \nвладелец фудтрака «Крошка Ли»",
					details:
						"Если у тебя есть планшет, ты скачиваешь программу Quick Resto, заводишь туда позиции и торгуешь. Для этого тебе нужно 2 часа.",
					photoCaption: "«Плюёшь на всё и открываешь свой бизнес»",
					bubbleType: BUBBLES_TYPES.PYATERIC,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/kroshka-li.png"
							alt="Логотип фудтрака «Крошка Ли»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropKroshkaLi
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/kroshka-li.jpg"
							alt="Владимир Иванюгин, владелец фудтрака «Крошка Ли»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/kroshka-li/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
				{
					type: BUSINESS_TYPES.MULTI,
					tabName: pagesLinks.businessTypes.multiformats.text,
					icon: MultiformatIcon,
					name: "Максим Кравцов, \nоснователь велокофейни «Вираж»",
					details: (
						<>
							В&nbsp;кафе в&nbsp;смене работает один человек&nbsp;&mdash; ему нужно
							всё делать быстро и&nbsp;не&nbsp;занимать голову лишними расчётами.
						</>
					),
					photoCaption:
						"«А почему не собирать велосипедистов в собственном \nкафе?»",
					bubbleType: BUBBLES_TYPES.HEX,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/virag.png"
							alt="Логотип велокофейни «Вираж»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropVirag
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/virag.jpg"
							alt="Максим Кравцов, основатель велокофейни «Вираж»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/virazh/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
			],
		}

	}

	if (locale === 'ru-KZ') {
		return {
			title: "Преодолей барьеры и открой возможности",
			titleClass: componentStyles.title__kz,
			subTitle: "создан для всех типов бизнеса",
			readMore: "Смотреть интервью",
			theme: "blueSky",
			businessType: [
				{
					type: BUSINESS_TYPES.COFFEE_SHOP,
					tabName: pagesLinks.businessTypes.coffeehouse.text,
					icon: CoffeeIcon,
					name: "Рауан Омар, \nоснователь кофейни «Korizza»",
					nameClass: componentStyles.businessName__kz,
					details:
						"Используем инструменты Quick Resto для оптимизации \nпроцессов и анализа продаж, чтобы развивать бизнес.",
					detailsTablet:
						"Используем инструменты Quick Resto для оптимизации процессов и анализа продаж, чтобы развивать бизнес.",
					detailsMobile:
						"Используем инструменты Quick Resto для оптимизации процессов и анализа продаж, чтобы развивать бизнес.",
					photoCaption: "«Девиз: \nКофе объединяет людей»",
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/korizza.png"
							alt="Логотип кофейни «Korizza»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={componentStyles.businessLogo__kz}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/korizza.jpeg"
							alt="Рауан Омар, основатель кофейни «Korizza»"
							// breakpoints={[424, 740, 920, 524]}
							// sizes="(max-width: 500px) 424px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 524px"
							objectFit="cover"
							placeholder="blurred"
							quality={100}
						/>
					),

				},
				{
					type: BUSINESS_TYPES.RESTAURANT,
					tabName: pagesLinks.businessTypes.restaurant.text,
					icon: RestaurantIcon,
					name: "Константин Кудобаев, \nсооснователь частного бара «Сезоны»",
					photoCaption: "«Чувствую себя героем игры, каждый день новые задания»",
					bubbleType: BUBBLES_TYPES.SECONDARY,
					details: (
						<>
							В&nbsp;первую очередь нас привлекло удобство интерфейса&nbsp;&mdash;
							это&nbsp;айфон на&nbsp;рынке систем автоматизации.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/seasons.png"
							alt="Логотип ресторана «Сезоны»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropSeasons
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/seasons.jpg"
							alt="Константин Кудобаев, сооснователь частного бара «Сезоны»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/sezony/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
				{
					type: BUSINESS_TYPES.BAR,
					tabName: pagesLinks.businessTypes.bar.text,
					icon: BarIcon,
					name:
						"Куаныш Нурахов, \nсооснователь изакая-бар «Tang»",
					photoCaption: (
						<div className={componentStyles.businessPhotoCaptionContent_tertiary}>
							«Среди тех, <br />
							кто любит лапшу <br />
							нет плохих людей!»
						</div>
					),
					bubbleType: BUBBLES_TYPES.LINEAR,
					details: (
						<>
							Quick Resto оптимизирует все процессы и&nbsp;помогает контролировать движение денежных средств
						</>
					),
					detailsMobile: (
						<>
							Используем инструменты Quick Resto для оптимизации процессов и&nbsp;анализа продаж, чтобы развивать бизнес.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/tang.png"
							alt="Логотип сети винотек и баров «Первый нос»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo__kz_bar
								// componentStyles.businessMainImageCropBar
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/tang.jpeg"
							alt="Куаныш Нурахов - сооснователь изакая-бар «Tang»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
				},
				{
					type: BUSINESS_TYPES.CAFE,
					tabName: pagesLinks.businessTypes.cafe.text,
					icon: CafeIcon,
					name: "Анастасия и Андрей, \nсооснователи «О, мой Боул!»",
					photoCaption: "«Общепит не только про еду, но и про эмоции»",
					bubbleType: BUBBLES_TYPES.QUATERNARY,
					details: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые
							нам&nbsp;раньше требовался отдельный человек.
						</>
					),
					detailsMobile: (
						<>
							Мы&nbsp;делегировали сотрудникам все процессы, на&nbsp;которые нам
							раньше требовался отдельный человек.
						</>
					),
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/oh-my-bowl.png"
							alt="Логотип Oh My Bowl"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropOhMyBowl
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/oh-my-bowl.jpg"
							alt="Анастасия и Андрей, сооснователи Oh My Bowl"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/ohmybowl/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
				{
					type: BUSINESS_TYPES.FOODTRACK,
					tabName: pagesLinks.businessTypes.foodtruck.text,
					icon: FoodtrackIcon,
					name: "Владимир Иванюгин, \nвладелец фудтрака «Крошка Ли»",
					details:
						"Если у тебя есть планшет, ты скачиваешь программу Quick Resto, заводишь туда позиции и торгуешь. Для этого тебе нужно 2 часа.",
					photoCaption: "«Плюёшь на всё и открываешь свой бизнес»",
					bubbleType: BUBBLES_TYPES.PYATERIC,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/kroshka-li.png"
							alt="Логотип фудтрака «Крошка Ли»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropKroshkaLi
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/kroshka-li.jpg"
							alt="Владимир Иванюгин, владелец фудтрака «Крошка Ли»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/kroshka-li/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
				{
					type: BUSINESS_TYPES.MULTI,
					tabName: pagesLinks.businessTypes.multiformats.text,
					icon: MultiformatIcon,
					name: "Максим Кравцов, \nоснователь велокофейни «Вираж»",
					details: (
						<>
							В&nbsp;кафе в&nbsp;смене работает один человек&nbsp;&mdash; ему нужно
							всё делать быстро и&nbsp;не&nbsp;занимать голову лишними расчётами.
						</>
					),
					photoCaption:
						"«А почему не собирать велосипедистов в собственном \nкафе?»",
					bubbleType: BUBBLES_TYPES.HEX,
					logo: (
						<StaticImage
							className={componentStyles.businessLogo}
							src="../../../components/_V2/BusinessTypes/assets/images/logo/virag.png"
							alt="Логотип велокофейни «Вираж»"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					),
					photo: (
						<StaticImage
							className={classNames(
								componentStyles.businessLogo,
								componentStyles.businessMainImageCropVirag
							)}
							src="../../../components/_V2/BusinessTypes/assets/images/persons/virag.jpg"
							alt="Максим Кравцов, основатель велокофейни «Вираж»"
							objectFit="cover"
							placeholder="blurred"
							quality={90}
						/>
					),
					link: {
						href: "/secret/virazh/",
						text: "Смотреть интервью",
						useGatsbyLink: true,
					},
				},
			],
		}

	}

	return {
		title: "Решения на основе данных",
		subTitle: "системный подход",
		readMore: "Смотреть интервью",
		theme: "blueSky",
		changeTitles: true, // Меняет тайтлы карточки, когда переключаешь слайды
		businessType: [
			{
				type: BUSINESS_TYPES.COFFEE_SHOP, // Нужно заменить данные
				tabName: pagesLinks.businessTypes.coffeehouse.text, // Нужно заменить данные
				icon: CoffeeIcon, // Нужно заменить данные
				name: "Владимир Михеев, основатель компании DO.BRO Coffee",
				cardTitle: "Решения на основе данных", // Для changeTitles
				cardSubtitle: "системный подход", // Для changeTitles
				details: (
					<>
						Автоматизация помогает нам думать системно. Там много
						<br />
						отчётов и если погрузиться досконально в аналитику,
						<br />
						ты можешь из неё выкручивать полезные инсайты. Отчёты
						<br />и аналитику используем для контроля новых решений.
					</>
				),
				detailsTablet:
					"Автоматизация помогает нам думать системно. Там много отчётов и если погрузиться досконально в аналитику, ты можешь из неё выкручивать полезные инсайты. Отчёты и аналитику используем для контроля новых решений.",
				detailsMobile:
					"Автоматизация помогает нам думать системно. Там много отчётов и если погрузиться досконально в аналитику, ты можешь из неё выкручивать полезные инсайты. Отчёты и аналитику используем для контроля новых решений.",
				photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/dobro-coffe.png"
						alt="Логотип компании DO.BRO Coffee"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo,
							componentStyles.businessMainImageCropDobroCoffe
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/dobro-coffe.png"
						alt="Владимир Михеев"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
			{
				type: BUSINESS_TYPES.RESTAURANT, // Нужно заменить данные
				tabName: pagesLinks.businessTypes.restaurant.text, // Нужно заменить данные
				icon: RestaurantIcon, // Нужно заменить данные
				bubbleType: BUBBLES_TYPES.SECONDARY,
				cardTitle: "Ставим сети как нам удобно", // Для changeTitles
				cardSubtitle: "опыт наших клиентов", // Для changeTitles
				name: "Анастасия Филлипова, основатель проекта «Шоу'рма»",
				details: (
					<>
						Для нашей сети было очень важно перейти на систему
						<br />
						с российскими корнями, но с опционалом не хуже
						<br />
						передовых иностранных аналогов и Quick Resto оправдали
						<br />
						наши ожидания.
					</>
				),
				detailsTablet:
					"Для нашей сети было очень важно перейти на систему с российскими корнями, но с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
				detailsMobile:
					"Для нашей сети было очень важно перейти на систему с российскими корнями, но с опционалом не хуже передовых иностранных аналогов и Quick Resto оправдали наши ожидания.",
				photoCaption: "«Здесь должна быть цитата»", // Нужно заменить данные
				logo: (
					<StaticImage
						className={componentStyles.businessLogo}
						src="../../../components/_V2/BusinessTypes/assets/images/logo/show-room.png"
						alt="Логотип проекта «Шоу'рма»"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				),
				photo: (
					<StaticImage
						className={classNames(
							componentStyles.businessLogo,
							componentStyles.businessMainImageCropShowRoom
						)}
						src="../../../components/_V2/BusinessTypes/assets/images/persons/show-room.png"
						alt="Анастасия Филлипова"
						objectFit="cover"
						placeholder="blurred"
						quality={90}
					/>
				),
			},
		],
	}
}
