import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

type ColorTheme = "greenLight" | "cyanLight" | "blueSkyLight" | "purpleLight"

interface Props {
	className?: string
	data: {
		subheading: string | JSX.Element // Заголовок
		heading: string | JSX.Element // Заголовок
		textarea: string | JSX.Element // Описание
		mediaContent: JSX.Element // Картинка
		backdrop: string // Задний фон должен передаваться как класс со стилями на псевдокласс
		theme: ColorTheme // Тема передается как строка
		reverse: boolean // Отразить по горизонтали
	}
}

// Компонент широкой карточки описания фишек системы
// ОБЯЗАТЕЛЕНЫ КАРТИНКИ ДЛЯ ДЕСТКОПА, ПЛАНШЕТА И МОБИЛКИ
// Картинка рассталкивает внутриние отступы, чтоб блок был как в дизайне
// Пример: /franchise/
export default function CardFullWidth2(props: Props) {
	const data = props.data || {}

	return (
		<section
			className={classNames(
				styles.container,
				styles[data.theme],
				data.backdrop,
				{
					[styles.reverse]: data.reverse,
				},
				props.className
			)}
		>
			<div className={styles.content__row}>
				<div className={styles.content__column}>
					<span className={styles.subheading}>{data.subheading}</span>
					<h2 className={styles.heading}>{data.heading}</h2>
					<p className={styles.textarea}>{data.textarea}</p>
				</div>
				<div className={styles.content__column}>{data.mediaContent}</div>
			</div>
		</section>
	)
}
