import * as React from "react"
import { SVGProps } from "react"

const PriceRiseIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7630_1703)">
			<ellipse
				cx="17.1403"
				cy="11.5057"
				rx="3.36193"
				ry="2.15123"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M20.5022 16.1929C19.8311 16.8359 18.5769 17.2685 17.1404 17.2685C15.7039 17.2685 14.4497 16.8359 13.7787 16.1929"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M20.5022 19.8049C19.8311 20.4479 18.5769 20.8805 17.1404 20.8805C15.7039 20.8805 14.4497 20.4479 13.7787 19.8049"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
			<path
				d="M10.3538 10.224V20.8802H5.78682V10.224H2.74219L8.07029 3.11987L10.7343 6.67194L12.0664 8.44798"
				stroke="#7D828C"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7630_1703">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default PriceRiseIcon
