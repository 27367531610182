import React from 'react'
import classNames from 'classnames'

import DefaultLayout from '../layout/default'

import ProductPresentation from '../pagesSections/franchise/ProductPresentation'
import CardFullWidthList from '../pagesSections/franchise/CardFullWidthList'
import UnderstandingFeatures from '../pagesSections/franchise/UnderstandingFeatures'
import CtaForm from '../pagesSections/franchise/CtaForm'
import BusinessTypes from '../components/_V2/BusinessTypes'
import MobileFeatures from '../pagesSections/franchise/MobileFeatures'
import HeadingTextareaAndCardList from '../components/_V3/HeadingTextareaAndCardList'
import ConsultationFeature from '../pagesSections/franchise/ConsultationFeature'
import OurCustomers from '../pagesSections/franchise/OurCustomers'
import Advantages from '../components/_V2/Advantages'
import AdditionalServices from '../pagesSections/index/AdditionalServices'
import KnowledgeBase from '../pagesSections/index/KnowledgeBase'

import Presentation from '../pagesSections/franchise/ProductPresentation/assets/franchise-presentation.png';

import { getData as getAdvantagesData } from '../pages-data/_V2/franchise/advantages'
import { getData as getBusinessTypes } from '../pages-data/_V2/common/business-types-second-compact'
import { getData as getHeadingTextareaAndCardListData } from '../pages-data/_V3/franchise/HeadingTextareaAndCardList/data'
import { externalLinks } from '../pages-data/_V2/common/links'

import styles from '../styles/_V2/pagesStyles/styles.module.scss'
import pageStyles from './styles/_V2/franchise.module.scss'

import { useLocalizationContext } from '../localization/useLocalizationContext'

export default function PageFranchise() {
  const localizationContext = useLocalizationContext()
  const businessTypes = getBusinessTypes(localizationContext.locale)
  const advantages = getAdvantagesData(localizationContext.locale)
  const headingTextareaAndCardList = getHeadingTextareaAndCardListData(localizationContext.locale)

  const metaTitle = {
    'ru-RU': 'Автоматизация франшизы — комплексная программа учета для сети | Quick Resto',
    'ru-KZ': 'Автоматизация франшизы — комплексная программа учета для сети | Quick Resto Казахстан',
    'kz-KZ': 'Автоматизация франшизы — комплексная программа учета для сети | Quick Resto Казахстан',
    'ru-BY': 'Автоматизация франшизы — комплексная программа учета для сети | Quick Resto Беларусь',
  }

  const currentTitle = metaTitle[localizationContext.locale]

  const metaUrl = {
    'ru-RU': `${externalLinks.quickrestoRU.href}franchise/`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}franchise/`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}franchise/`,
    'ru-BY': `${externalLinks.quickrestoBY.href}franchise/`,
  }

  const currentCanonicalLink = metaUrl[localizationContext.locale]

  const metaDescription = {
    'ru-RU': 'Автоматизация франшизы ✔ Контроль и учет в сети франчайзи ✔ Оптимизация работы франчайзера ✔ Единая система лояльности для всей сети',
    'ru-KZ': 'Автоматизация франшизы ✔ Контроль и учет в сети франчайзи ✔ Оптимизация работы франчайзера ✔ Единая система лояльности для всей сети. Автоматизируй бизнес в Казахстане',
    'kz-KZ': 'Автоматизация франшизы ✔ Контроль и учет в сети франчайзи ✔ Оптимизация работы франчайзера ✔ Единая система лояльности для всей сети. Автоматизируй бизнес в Казахстане',
    'ru-BY': 'Автоматизация франшизы ✔ Контроль и учет в сети франчайзи ✔ Оптимизация работы франчайзера ✔ Единая система лояльности для всей сети. Автоматизируй бизнес в Беларуси',
  }

  const ogImage = {
    'ru-RU': `${externalLinks.quickrestoRU.href}${Presentation}`,
    'ru-KZ': `${externalLinks.quickrestoKZ.href}${Presentation}`,
    'kz-KZ': `${externalLinks.quickrestoKZ.href}${Presentation}`,
    'ru-BY': `${externalLinks.quickrestoBY.href}${Presentation}`,
  }

  const metaTags = [
    // {
    //   name: 'keywords',
    //   content: 'автоматизация, ресторан, кафе, программа, учёт, система, pos-терминал, онлайн-касса',
    // },
    {
      name: 'description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:url',
      content: currentCanonicalLink,
    },
    {
      property: 'og:title',
      content: currentTitle,
    },
    {
      property: 'og:description',
      content: metaDescription[localizationContext.locale],
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: ogImage[localizationContext.locale]
    },
  ]

  return (
    <DefaultLayout
      title={currentTitle}
      mainWrapperClassName={styles.pageWrapper}
      metaTags={metaTags}
      linkCanonical={currentCanonicalLink}
      footerClassName={styles.pageSection}
      schemaType="Product"
      schemaName={currentTitle}
      schemaDescription={metaTags.find((tag) => tag.name === 'description').content}
      schemaImage={metaTags.find((tag) => tag.property === 'og:image').content}
    >
      <ProductPresentation />

      <Advantages
        items={advantages}
        className={classNames(
				  styles.pageSection,
				  pageStyles.franchise__advantages,
          pageStyles.franchise__advantages_franchise
        )}
      />

      <OurCustomers
        className={classNames(
				  styles.pageSection,
				  pageStyles.franchise__ourCustomers,
          pageStyles.franchise__customersFranchise
        )}
        isFranchisePage
      />

      <CardFullWidthList className={styles.pageSection} />

      <UnderstandingFeatures className={styles.pageSection} />

      <CtaForm className={styles.pageSection} />

      <BusinessTypes
        data={businessTypes}
        className={styles.pageSection}
      />

      <MobileFeatures className={styles.pageSection} />

      <HeadingTextareaAndCardList
        key={localizationContext.locale}
        data={headingTextareaAndCardList}
        className={styles.pageSection}
      />

      <ConsultationFeature className={styles.pageSection} />

      <AdditionalServices className={styles.pageSection} />

      <KnowledgeBase type="horizontal" className={styles.pageSection} />
    </DefaultLayout>
  )
}
