import React from "react"
import classNames from "classnames"

import { StaticImage } from "gatsby-plugin-image"

import CheckedMagnifyingGlassIcon from "../../../assets/_V2/svg/CheckedMagnifyingGlassIcon"
import PriceRiseIcon from "../../../assets/_V2/svg/PriceRiseIcon"
import MoneyIcon from "../../../assets/_V2/svg/MoneyIcon"
import WeighterIcon from "../../../assets/_V2/svg/WeighterIcon"
import CircleAndSquare from "../../../assets/_V2/svg/CircleAndSquare"

import NoteIcon from "../../../assets/_V2/svg/NoteIcon"

import styles from "./styles.module.scss"

import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			title: "Порядок во всех делах",
			headerPlacement: "left",
			features: [
				{
					icon: <CheckedMagnifyingGlassIcon />,
					name: "Управляй качеством",
					description: "Отчёты покаждому франчайзи для контроля бизнеса",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer1}>
							<StaticImage
								className={styles.understandingFeatures__image1}
								src="./assets/franchise-understanding-1-BY.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos1
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Отчёты по
								<br />
								каждому&nbsp;франчайзи
								<br />
								для контроля
								<br />
								бизнеса
								<br />
							</div>
						</div>
					),
				},
				{
					icon: <PriceRiseIcon />,
					name: "Контролируй цены",
					description: "Гибкая политика цен для каждого франчайзи",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer2}>
							<StaticImage
								className={styles.understandingFeatures__image2}
								src="./assets/franchise-understanding-2-BY.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos2
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Гибкая
								<br />
								политика цен
								<br />
								для каждого
								<br />
								франчайзи
								<br />
							</div>
						</div>
					),
				},
				{
					icon: <MoneyIcon />,
					name: "Принимай оплату в любом виде",
					description: "Наличные, банковские карты, бонусы, собственные типы оплат",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer3}>
							<StaticImage
								className={styles.understandingFeatures__image3}
								src="./assets/franchise-understanding-3-BY.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos3
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Наличные,
								<br />
								банковские карты,
								<br />
								бонусы,&nbsp;собственные
								<br />
								типы оплат
							</div>
						</div>
					),
				},
				{
					icon: <CircleAndSquare />,
					name: "Установи Quick Resto Manager",
					description: "Доступ к аналитике через мобильное приложение",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer5}>
							<StaticImage
								className={styles.understandingFeatures__image5}
								src="./assets/franchise-understanding-5-BY.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos5
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Доступ
								<br />
								к аналитике
								<br />
								через мобильное
								<br />
								приложение
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'kz-KZ') {
		return {
			title: <>Барлық<br />процестерде тәртіп</>,
			titleTablet: "Барлық процестерде тәртіп",
			titleMobile: <>Барлық<br />процестерде тәртіп</>,
			headerPlacement: "left",
			features: [
				{
					icon: <CheckedMagnifyingGlassIcon />,
					name: "Сапаны басқару",
					description: "Бизнесті бақылау үшін әр франчайзи бойынша есептер",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer1}>
							<StaticImage
								className={styles.understandingFeatures__image1}
								src="./assets/franchise-understanding-1-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos1
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Бизнесті бақылау үшін әр франчайзи бойынша есептер
							</div>
						</div>
					),
				},
				{
					icon: <PriceRiseIcon />,
					name: "Бағаларды бақылаңыз",
					description: "Әр франчайзи үшін икемді бағалар саясаты",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer2}>
							<StaticImage
								className={styles.understandingFeatures__image2}
								src="./assets/franchise-understanding-2-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos2
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Әр франчайзи үшін икемді бағалар саясаты
							</div>
						</div>
					),
				},
				{
					icon: <MoneyIcon />,
					name: "Кез келген түрінде төлемді қабылдаңыз",
					description: "Қолма-қол ақша, банк карталары, бонустар, өз төлем түрлері",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer3}>
							<StaticImage
								className={styles.understandingFeatures__image3}
								src="./assets/franchise-understanding-3-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos3
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Қолма-қол ақша, банк карталары, бонустар, өз төлем түрлері
							</div>
						</div>
					),
				},
				{
					icon: <CircleAndSquare />,
					name: "Quick Resto Manager орнатыңыз",
					description: "Мобильді қосымша арқылы аналитикаға қолжеткізу",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer5}>
							<StaticImage
								className={styles.understandingFeatures__image5}
								src="./assets/franchise-understanding-5-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos5
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Мобильді қосымша арқылы аналитикаға қолжеткізу
							</div>
						</div>
					),
				},
			],
		}
	}

	if (locale === 'ru-KZ') {
		return {
			title: <>Порядок<br />во всех процессах</>,
			titleTablet: "Порядок во всех процессах",
			titleMobile: <>Порядок<br />во всех процессах</>,
			headerPlacement: "left",
			features: [
				{
					icon: <CheckedMagnifyingGlassIcon />,
					name: "Управляй качеством",
					description: "Отчёты покаждому франчайзи для контроля бизнеса",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer1}>
							<StaticImage
								className={styles.understandingFeatures__image1}
								src="./assets/franchise-understanding-1-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos1
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Отчёты по
								<br />
								каждому&nbsp;франчайзи
								<br />
								для контроля
								<br />
								бизнеса
								<br />
							</div>
						</div>
					),
				},
				{
					icon: <PriceRiseIcon />,
					name: "Контролируй цены",
					description: "Гибкая политика цен для каждого франчайзи",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer2}>
							<StaticImage
								className={styles.understandingFeatures__image2}
								src="./assets/franchise-understanding-2-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos2
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Гибкая
								<br />
								политика цен
								<br />
								для каждого
								<br />
								франчайзи
								<br />
							</div>
						</div>
					),
				},
				{
					icon: <MoneyIcon />,
					name: "Принимай оплату в любом виде",
					description: "Наличные, банковские карты, бонусы, собственные типы оплат",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer3}>
							<StaticImage
								className={styles.understandingFeatures__image3}
								src="./assets/franchise-understanding-3-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos3
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Наличные,
								<br />
								банковские карты,
								<br />
								бонусы,&nbsp;собственные
								<br />
								типы оплат
							</div>
						</div>
					),
				},
				{
					icon: <CircleAndSquare />,
					name: "Установи Quick Resto Manager",
					description: "Доступ к аналитике через мобильное приложение",
					mediaContent: (
						<div className={styles.understandingFeatures__imageContainer5}>
							<StaticImage
								className={styles.understandingFeatures__image5}
								src="./assets/franchise-understanding-5-KZ.png"
								alt="отчеты по работе поваров"
								objectFit="contain"
								placeholder="blurred"
								quality={90}
							/>
						</div>
					),
					badge: (
						<div
							className={classNames(
								styles.understandingFeaturesBadge,
								styles.understandingFeaturesBadgeStandart,
								styles.understandingFeaturesBadgeStandartPos5
							)}
						>
							<div className={styles.understandingFeaturesBadge__content}>
								<NoteIcon />
								Доступ
								<br />
								к аналитике
								<br />
								через мобильное
								<br />
								приложение
							</div>
						</div>
					),
				},
			],
		}
	}

	return {
		title: "Везде, всё и сразу!",
		headerPlacement: "left",
		features: [
			{
				icon: <CheckedMagnifyingGlassIcon />,
				name: "Управляй качеством",
				description: "Отчёты покаждому франчайзи для контроля бизнеса",
				mediaContent: (
					<div className={styles.understandingFeatures__imageContainer1}>
						<StaticImage
							className={styles.understandingFeatures__image1}
							src="./assets/franchise-understanding-1.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart,
							styles.understandingFeaturesBadgeStandartPos1
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Отчёты по
							<br />
							каждому&nbsp;франчайзи
							<br />
							для контроля
							<br />
							бизнеса
							<br />
						</div>
					</div>
				),
			},
			{
				icon: <PriceRiseIcon />,
				name: "Контролируй цены",
				description: "Гибкая политика цен для каждого франчайзи",
				mediaContent: (
					<div className={styles.understandingFeatures__imageContainer2}>
						<StaticImage
							className={styles.understandingFeatures__image2}
							src="./assets/franchise-understanding-2.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart,
							styles.understandingFeaturesBadgeStandartPos2
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Гибкая
							<br />
							политика цен
							<br />
							для каждого
							<br />
							франчайзи
							<br />
						</div>
					</div>
				),
			},
			{
				icon: <MoneyIcon />,
				name: "Принимай оплату в любом виде",
				description: "Наличные, банковские карты, бонусы, собственные типы оплат",
				mediaContent: (
					<div className={styles.understandingFeatures__imageContainer3}>
						<StaticImage
							className={styles.understandingFeatures__image3}
							src="./assets/franchise-understanding-3.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart,
							styles.understandingFeaturesBadgeStandartPos3
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Наличные,
							<br />
							банковские карты,
							<br />
							бонусы,&nbsp;собственные
							<br />
							типы оплат
						</div>
					</div>
				),
			},
			{
				icon: <WeighterIcon />,
				name: "Соблюдай закон",
				description: "Система поддерживает: 54-ФЗ, ЕГАИС, Меркурий, Честный знак",
				mediaContent: (
					<div className={styles.understandingFeatures__imageContainer4}>
						<StaticImage
							className={styles.understandingFeatures__image4}
							src="./assets/franchise-understanding-4.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<span className={styles.understandingFeatures__label}>
						Система поддерживает: 54-ФЗ, ЕГАИС, Меркурий, Честный знак
					</span>
					</div>
				),
			},
			{
				icon: <CircleAndSquare />,
				name: "Установи Quick Resto Manager",
				description: "Доступ к аналитике через мобильное приложение",
				mediaContent: (
					<div className={styles.understandingFeatures__imageContainer5}>
						<StaticImage
							className={styles.understandingFeatures__image5}
							src="./assets/franchise-understanding-5.png"
							alt="отчеты по работе поваров"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				badge: (
					<div
						className={classNames(
							styles.understandingFeaturesBadge,
							styles.understandingFeaturesBadgeStandart,
							styles.understandingFeaturesBadgeStandartPos5
						)}
					>
						<div className={styles.understandingFeaturesBadge__content}>
							<NoteIcon />
							Доступ
							<br />
							к аналитике
							<br />
							через мобильное
							<br />
							приложение
						</div>
					</div>
				),
			},
		],
	}
}
