import React from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

type CardTheme = "white" | "grayLight"

type CardsType = {
	id: number // id карточки
	icon: JSX.Element // Иконка
	heading: string | JSX.Element // Заголовок
	content: string | JSX.Element // Контент
	theme: CardTheme // Тема карточки
}

interface Props {
	className?: string // Класс
	anchor?: string // Якорь
	data: {
		heading: string | JSX.Element // Заголовок
		textarea: string | JSX.Element // Описание
		cardList: [CardsType] // Карточки
	}
}

// Компонент с заголовком, описание и карточками
// Пример: /franchise/ и /service/
export default function HeadingTextareaAndCardList(props: Props) {
	const data = props.data || []

	const renderCardList = (): JSX.Element => (
		<div className={styles.card__list}>
			{data.cardList.map(card => (
				<div key={card.id} className={classNames(styles.card, card.theme)}>
					<div className={styles.card__icon}>{card.icon}</div>
					<h3 className={styles.card__heading}>{card.heading}</h3>
					<div className={styles.card__content}>{card.content}</div>
				</div>
			))}
		</div>
	)

	return (
		<section id={props.anchor} className={classNames(styles.section, props.className)}>
			<h2 className={styles.heading}>{data.heading}</h2>
			<p className={styles.textarea}>{data.textarea}</p>
			{data.cardList.length > 0 && renderCardList()}
		</section>
	)
}
