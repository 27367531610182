import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
  if (locale === 'ru-BY') {
    return [
      {
        img: (
            <StaticImage
                src="./assets/advantages-1.png"
                alt="Регулярно о главном"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Регулярно о главном',
        description:
            'Отчёты по работе каждого франчайзи и сети целиком. Сравнивай показатели и принимай бизнес-решения.',
      },
      {
        img: (
            <StaticImage
                src="./assets/advantages-2.png"
                alt="С большим размахом"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'С большим размахом',
        description:
            'Зарегистрируй новое облако в Quick Resto, и франчайзи получит готовую систему для управления заведением.',
      },
      {
        img: (
            <StaticImage
                src="./assets/advantages-3.png"
                alt="Легко для каждого"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Легко для каждого',
        description:
            'Решение для франшиз экономит время обучения персонала. С ним легко и быстро подготовиться к работе даже новичкам. ',
      },
    ]
  }

  if (locale === 'kz-KZ') {
    return  [
      {
        img: (
            <StaticImage
                src="./assets/advantages-1.png"
                alt="Регулярно о главном"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Бастысы туралы жүйелі түрде',
        description:
            'Әр франчайзидің және тұтастай желінің жұмысы бойынша есептер. Көрсеткіштерді салыстырыңыз және басқарушылық шешімдерді қабылдаңыз.',
      },
      {
        img: (
            <StaticImage
                src="./assets/advantages-2.png"
                alt="С большим размахом"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Үлкен қарқынмен',
        description:
            'Quick Resto-да жаңа бұлтты тіркеңіз және франчайзи мккемені басқару үшін дайын жүйені алады.',
      },
      {
        img: (
            <StaticImage
                src="./assets/advantages-3.png"
                alt="Легко для каждого"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Әркім үшін оңай',
        description:
            'Франшизаларға арналған шешім персоналды оқыту уақытын үнемдейді. Онымен бірге тіпті жаңадан бастаушыларға да жұмысқа дайындалу оңай әрі жылдам болады. ',
      },
    ]
  }

  if (locale === 'ru-KZ') {
    return  [
      {
        img: (
            <StaticImage
                src="./assets/advantages-1.png"
                alt="Регулярно о главном"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Регулярно о главном',
        description:
            'Отчёты по работе каждого франчайзи и сети целиком. Сравнивай показатели и принимай бизнес-решения.',
      },
      {
        img: (
            <StaticImage
                src="./assets/advantages-2.png"
                alt="С большим размахом"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'С большим размахом',
        description:
            'Зарегистрируй новое облако в Quick Resto, и франчайзи получит готовую систему для управления заведением.',
      },
      {
        img: (
            <StaticImage
                src="./assets/advantages-3.png"
                alt="Легко для каждого"
                objectFit="contain"
                placeholder="blurred"
                quality={90}
            />
        ),
        title: 'Легко для каждого',
        description:
            'Решение для франшиз экономит время обучения персонала. С ним легко и быстро подготовиться к работе даже новичкам.',
      },
    ]
  }

  return [
    {
      img: (
          <StaticImage
              src="./assets/advantages-1.png"
              alt="Регулярно о главном"
              objectFit="contain"
              placeholder="blurred"
              quality={90}
          />
      ),
      title: 'Регулярно о главном',
      description:
          'Отчеты по работе каждого франчайзи и сети целиком. Сравнивай показатели и принимай управленческие решения.',
    },
    {
      img: (
          <StaticImage
              src="./assets/advantages-2.png"
              alt="С большим размахом"
              objectFit="contain"
              placeholder="blurred"
              quality={90}
          />
      ),
      title: 'С большим размахом',
      description:
          'Зарегистрируй новое облако в Quick Resto и франчайзи получит готовую систему для управления заведением.',
    },
    {
      img: (
          <StaticImage
              src="./assets/advantages-3.png"
              alt="Легко для каждого"
              objectFit="contain"
              placeholder="blurred"
              quality={90}
          />
      ),
      title: 'Легко для каждого',
      description:
          'Решение для франшиз экономит время обучения персонала. С ним легко и быстро подготовиться к работе даже новичкам.',
    },
  ]
}

export const advantages = [
  {
    img: (
      <StaticImage
        src="./assets/advantages-1.png"
        alt="Регулярно о главном"
        objectFit="contain"
        placeholder="blurred"
        quality={90}
      />
    ),
    title: 'Регулярно о главном',
    description:
			'Отчеты по работе каждого франчайзи и сети целиком. Сравнивай показатели и принимай управленческие решения.',
  },
  {
    img: (
      <StaticImage
        src="./assets/advantages-2.png"
        alt="С большим размахом"
        objectFit="contain"
        placeholder="blurred"
        quality={90}
      />
    ),
    title: 'С большим размахом',
    description:
			'Зарегистрируй новое облако в Quick Resto и франчайзи получит готовую систему для управления заведением.',
  },
  {
    img: (
      <StaticImage
        src="./assets/advantages-3.png"
        alt="Легко для каждого"
        objectFit="contain"
        placeholder="blurred"
        quality={90}
      />
    ),
    title: 'Легко для каждого',
    description:
			'Решение для франшиз экономит время обучения персонала. С ним легко и быстро подготовиться к работе даже новичкам.',
  },
]
