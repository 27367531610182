import * as React from "react"
import { SVGProps } from "react"

const MoneyIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<g clipPath="url(#clip0_7630_4992)">
			<circle
				cx="12"
				cy="11.9997"
				r="9.25119"
				stroke="#7D828C"
				strokeWidth="2"
			/>
			<path
				d="M9.1498 17.0879C9.1498 17.6402 9.59751 18.0879 10.1498 18.0879C10.7021 18.0879 11.1498 17.6402 11.1498 17.0879H9.1498ZM10.1498 6.91162V5.91162C9.59751 5.91162 9.1498 6.35934 9.1498 6.91162H10.1498ZM8.29956 10.5372C7.74728 10.5372 7.29956 10.9849 7.29956 11.5372C7.29956 12.0895 7.74728 12.5372 8.29956 12.5372V10.5372ZM8.35151 13.3126C7.79922 13.3126 7.35151 13.7603 7.35151 14.3126C7.35151 14.8649 7.79922 15.3126 8.35151 15.3126V13.3126ZM13.8503 15.3126C14.4026 15.3126 14.8503 14.8649 14.8503 14.3126C14.8503 13.7603 14.4026 13.3126 13.8503 13.3126V15.3126ZM11.1498 17.0879V11.5372H9.1498V17.0879H11.1498ZM11.1498 11.5372V6.91162H9.1498V11.5372H11.1498ZM10.1498 7.91162H13.3877V5.91162H10.1498V7.91162ZM13.3877 10.5372H10.1498V12.5372H13.3877V10.5372ZM10.1498 10.5372H8.29956V12.5372H10.1498V10.5372ZM14.7005 9.22442C14.7005 9.94946 14.1128 10.5372 13.3877 10.5372V12.5372C15.2173 12.5372 16.7005 11.054 16.7005 9.22442H14.7005ZM13.3877 7.91162C14.1128 7.91162 14.7005 8.49938 14.7005 9.22442H16.7005C16.7005 7.39481 15.2173 5.91162 13.3877 5.91162V7.91162ZM8.35151 15.3126H13.8503V13.3126H8.35151V15.3126Z"
				fill="#7D828C"
			/>
		</g>
		<defs>
			<clipPath id="clip0_7630_4992">
				<rect
					width="24"
					height="24"
					fill="white"
					transform="translate(0 -0.000244141)"
				/>
			</clipPath>
		</defs>
	</svg>
)

export default MoneyIcon
