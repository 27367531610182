import { Data } from "../../../components/_V2/BusinessTypes/types"
import businessTypesSecond, {getData as getCommonData} from "./business-types-second"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";

import {Locales} from "../../../../localization/types";

export const getData = ((locale: Locales) => {
	return {
		...getCommonData(locale),
		type: "compact",
	}
})
