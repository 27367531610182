import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { pagesLinks, pagesLinksKz } from "../../../pages-data/_V2/common/links"

import styles from "./styles.module.scss"
import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return {
			pageName: "УПРАВЛЕНИЕ франшизой",
			desktopTitle: <>Умножай возможности бизнеса с&nbsp;франшизой</>,
			subTitle: (
				<>
				<span className={styles.contentDesktop}>
					Когда проект уже вовсю работает, следующий шаг — рост до сети. В единой системе Quick Resto есть все инструменты по подготовке к продаже и развитию франшизы. Начинаем умножать!
				</span>
				<span className={styles.contentTablet}>
					Когда проект уже вовсю работает, следующий шаг — рост до сети. В единой системе Quick Resto есть все инструменты по подготовке к продаже и развитию франшизы. Начинаем умножать!
				</span>
				<span className={styles.contentMobile}>
				Следующий шаг сформированного проекта — развитие в сеть. В единой системе Quick Resto есть все инструменты по подготовке <br/>
				к продаже и развитию франшизы. Начинаем умножать!
				</span>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<div className={styles.image}>
					<StaticImage
						alt="управление персоналов в ресторане"
						src="./assets/franchise-presentation.png"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
		}
	}

	if (locale === 'kz-KZ') {
		return {
			pageName: "Франшизаны басқару",
			desktopTitle: <>Франшизамен<br />мүмкіндіктерді<br />ұлғайтыңыз</>,
			tabletTitle: "Франшизамен мүмкіндіктерді ұлғайтыңыз",
			mobileTitle: "Франшизамен мүмкіндіктерді ұлғайтыңыз",

			subTitle: (
				<>
			<span className={styles.contentDesktop}>
				Қалыптастырылған жобаның келесі қадамы - желіге дамыту. Quick Resto бірыңғай жүйесінде сатуға дайындық
				және франшизаны дамыту бойынша барлық құралдар бар. Көбейтуді бастаймыз!
			</span>
					<span className={styles.contentTablet}>
				Қалыптастырылған жобаның келесі қадамы - желіге дамыту. Quick Resto бірыңғай жүйесінде сатуға дайындық
						және франшизаны дамыту бойынша барлық құралдар бар. Көбейтуді бастаймыз!
			</span>
					<span className={styles.contentMobile}>
				Қалыптастырылған жобаның келесі қадамы - желіге дамыту. Quick Resto бірыңғай жүйесінде сатуға дайындық
						және франшизаны дамыту бойынша барлық құралдар бар. Көбейтуді бастаймыз!
			</span>
				</>
			),
			cta: pagesLinksKz.registration.text,
			desktopCtaDetails:
				"Тез, ыңғайлы және екі апта тегін",
			ctaLink: pagesLinks.registration,
			figure: (
				<div className={styles.image}>
					<StaticImage
						alt="управление персоналов в ресторане"
						src="./assets/franchise-presentation.png"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
		}
	}

	if (locale === 'ru-KZ') {
		return {
			pageName: "УПРАВЛЕНИЕ франшизой",
			desktopTitle: <>Увеличивай <br/>возможности <br/>
				с франшизой</>,
			tabletTitle: "Увеличивай возможности с франшизой",
			mobileTitle: "Увеличивай возможности с франшизой",

			subTitle: (
				<>
			<span className={styles.contentDesktop}>
				Следующий шаг сформированного проекта —<br />
				развитие в сеть. В единой системе Quick Resto
				<br />
				есть все инструменты по подготовке к продаже
				<br />и развитию франшизы. Начинаем умножать!
			</span>
					<span className={styles.contentTablet}>
				Следующий шаг сформированного проекта — развитие в сеть. В единой
				системе Quick Resto есть все инструменты по подготовке к продаже и
				развитию франшизы. Начинаем умножать!
			</span>
					<span className={styles.contentMobile}>
				Следующий шаг сформированного <br />
				проекта — развитие в сеть. В единой
				<br />
				системе Quick Resto есть все инструменты
				<br />
				по подготовке
				<br />
				к продаже и развитию франшизы.
				<br />
				Начинаем умножать!
			</span>
				</>
			),
			cta: pagesLinks.registration.text,
			desktopCtaDetails:
				"Быстро, удобно и две недели бесплатно",
			ctaLink: pagesLinks.registration,
			figure: (
				<div className={styles.image}>
					<StaticImage
						alt="управление персоналов в ресторане"
						src="./assets/franchise-presentation.png"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
		}
	}

	return {
		pageName: "УПРАВЛЕНИЕ франшизой",
		desktopTitle: <>Умножай возможности бизнеса с&nbsp;франшизой</>,
		subTitle: (
			<>
			<span className={styles.contentDesktop}>
				Следующий шаг сформированного проекта —<br />
				развитие в сеть. В единой системе Quick Resto
				<br />
				есть все инструменты по подготовке к продаже
				<br />и развитию франшизы. Начинаем умножать!
			</span>
				<span className={styles.contentTablet}>
				Следующий шаг сформированного проекта — развитие в сеть. В единой
				системе Quick Resto есть все инструменты по подготовке к продаже и
				развитию франшизы. Начинаем умножать!
			</span>
				<span className={styles.contentMobile}>
				Следующий шаг сформированного <br />
				проекта — развитие в сеть. В единой
				<br />
				системе Quick Resto есть все инструменты
				<br />
				по подготовке
				<br />
				к продаже и развитию франшизы.
				<br />
				Начинаем умножать!
			</span>
			</>
		),
		cta: pagesLinks.registration.text,
		desktopCtaDetails:
			"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
		ctaLink: pagesLinks.registration,
		figure: (
			<div className={styles.image}>
				<StaticImage
					alt="управление персоналов в ресторане"
					src="./assets/franchise-presentation.png"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</div>
		),
	}
}

export default {
	pageName: "УПРАВЛЕНИЕ франшизой",
	desktopTitle: <>Умножай возможности бизнеса с&nbsp;франшизой</>,
	subTitle: (
		<>
			<span className={styles.contentDesktop}>
				Следующий шаг сформированного проекта —<br />
				развитие в сеть. В единой системе Quick Resto
				<br />
				есть все инструменты по подготовке к продаже
				<br />и развитию франшизы. Начинаем умножать!
			</span>
			<span className={styles.contentTablet}>
				Следующий шаг сформированного проекта — развитие в сеть. В единой
				системе Quick Resto есть все инструменты по подготовке к продаже и
				развитию франшизы. Начинаем умножать!
			</span>
			<span className={styles.contentMobile}>
				Следующий шаг сформированного <br />
				проекта — развитие в сеть. В единой
				<br />
				системе Quick Resto есть все инструменты
				<br />
				по подготовке
				<br />
				к продаже и развитию франшизы.
				<br />
				Начинаем умножать!
			</span>
		</>
	),
	cta: pagesLinks.registration.text,
	desktopCtaDetails:
		"За 10 минут, без дорогого оборудования,\nс удобным интерфейсом. И две \nнедели бесплатно.",
	ctaLink: pagesLinks.registration,
	figure: (
		<div className={styles.image}>
			<StaticImage
				alt="управление персоналов в ресторане"
				src="./assets/franchise-presentation.png"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		</div>
	),
}
