import React, { useEffect, useRef } from "react"
import classNames from "classnames"

import styles from "./styles.module.scss"

import data from "./data"
import data2 from "./data2"

interface Props {
	className?: string
	isGray?: boolean
	isPricePage?: boolean
	isFranchisePage?: boolean
}

export default function OurCustomers(props: Props) {
	const isFranchisePage =
		props.isFranchisePage !== undefined ? props.isFranchisePage : false

	const currentData = isFranchisePage ? data2 : data

	const logoContainer: { current: Array<HTMLElement | null> } = useRef([])

	const getRandomOrder = (): number => {
		return Math.ceil(Math.random() * currentData.customers.length)
	}

	/*
    Перемешивание логотипов через order, потому что если перемешивать массив с клиентами,
    Гетсби кидает список через спину и раскидывает каждый логотип в чужой контейнер с чужим размером.
    */
	useEffect(() => {
		logoContainer.current.forEach(logo => {
			if (logo) {
				logo.style.order = "" + getRandomOrder()
			}
		})
	}, [])

	return (
		<section
			className={classNames(
				styles.section,
				props.className,
				{ [styles.sectionGray]: props.isGray },
				{ [styles.sectionPrice]: props.isPricePage }
			)}
		>
			<h2
				className={classNames(styles.title, {
					[styles.titleGray]: props.isGray,
				})}
			>
				{currentData.title}
			</h2>
			<div className={styles.logoListContainer}>
				<ul
					className={classNames(
						styles.logoList,
						{ [styles.logoListGray]: props.isGray },
						{ [styles.logoListPrice]: props.isPricePage }
					)}
				>
					{currentData.customers.map((client, i) => (
						<li key={client.name} ref={ref => (logoContainer.current[i] = ref)}>
							{client.logo(client.name)}
						</li>
					))}
				</ul>
			</div>
		</section>
	)
}
