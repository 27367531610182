import * as React from "react"
import { SVGProps } from "react"

const CardIconFour = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="150"
		height="150"
		viewBox="0 0 150 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M96.5549 45.0176H112.819C114.635 45.0176 116.107 46.4899 116.107 48.306V98.923C116.107 100.739 114.635 102.211 112.819 102.211H37.1856C35.3695 102.211 33.8972 100.739 33.8972 98.9229V48.306C33.8972 46.4898 35.3695 45.0176 37.1856 45.0176H47.4498"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M48.1699 122.771L101.836 122.771"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M62.4434 102.211L62.4434 122.772"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M87.5586 107.966L87.5586 122.772"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M104.996 95.3714V88.4896C104.996 81.9928 99.7293 76.7261 93.2325 76.7261H56.7655C50.2687 76.7261 45.002 81.9928 45.002 88.4896V95.3714"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M75.0006 66.1388C86.6949 66.1388 96.175 56.6587 96.175 44.9644C96.175 33.2701 86.6949 23.79 75.0006 23.79C63.3063 23.79 53.8262 33.2701 53.8262 44.9644C53.8262 56.6587 63.3063 66.1388 75.0006 66.1388Z"
			stroke="#3021E5"
			strokeWidth="3"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M82.0596 44.9642C82.0596 48.8623 78.8996 52.0223 75.0015 52.0223C71.1034 52.0223 67.9434 48.8623 67.9434 44.9642"
			stroke="#3021E5"
			strokeWidth="3"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
		<path
			d="M68.084 102.212L66.6082 101.944C66.5286 102.381 66.6473 102.832 66.9323 103.173C67.2173 103.515 67.6392 103.712 68.084 103.712L68.084 102.212ZM71.0342 86.0046V84.5046C70.3094 84.5046 69.6882 85.0229 69.5584 85.736L71.0342 86.0046ZM81.9233 102.212L81.9233 103.712C82.3681 103.712 82.79 103.515 83.075 103.173C83.36 102.831 83.4787 102.381 83.399 101.943L81.9233 102.212ZM80.7635 95.8405L79.2877 96.1092L80.7635 95.8405ZM79.6037 89.4691L81.0795 89.2005V89.2005L79.6037 89.4691ZM78.9731 86.0046L80.4488 85.736C80.319 85.0229 79.6979 84.5046 78.9731 84.5046V86.0046ZM69.5597 102.481L72.5099 86.2733L69.5584 85.736L66.6082 101.944L69.5597 102.481ZM81.9232 100.712L68.084 100.712L68.084 103.712L81.9233 103.712L81.9232 100.712ZM79.2877 96.1092L80.4475 102.481L83.399 101.943L82.2392 95.5719L79.2877 96.1092ZM78.128 89.7377L79.2877 96.1092L82.2392 95.5719L81.0795 89.2005L78.128 89.7377ZM77.4973 86.2733L78.128 89.7377L81.0795 89.2005L80.4488 85.736L77.4973 86.2733ZM78.9731 84.5046H71.0342V87.5046H78.9731V84.5046Z"
			fill="#3021E5"
		/>
		<path
			d="M69.3502 76.7263L69.3502 75.2263C68.9053 75.2263 68.4835 75.4237 68.1985 75.7653C67.9135 76.1068 67.7948 76.5573 67.8744 76.9949L69.3502 76.7263ZM80.6581 76.7263L82.1339 76.9949C82.2136 76.5573 82.0949 76.1068 81.8099 75.7653C81.5249 75.4237 81.103 75.2263 80.6581 75.2263L80.6581 76.7263ZM78.9692 86.0049L78.9692 87.5049C79.694 87.5049 80.3151 86.9866 80.4449 86.2735L78.9692 86.0049ZM71.0391 86.0049L69.5634 86.2735C69.6932 86.9866 70.3143 87.5049 71.0391 87.5049L71.0391 86.0049ZM69.3502 78.2263L80.6581 78.2263L80.6581 75.2263L69.3502 75.2263L69.3502 78.2263ZM79.1824 76.4577L77.4934 85.7363L80.4449 86.2735L82.1339 76.9949L79.1824 76.4577ZM78.9692 84.5049L71.0391 84.5049L71.0391 87.5049L78.9692 87.5049L78.9692 84.5049ZM72.5149 85.7363L70.8259 76.4577L67.8744 76.9949L69.5634 86.2735L72.5149 85.7363Z"
			fill="#3021E5"
		/>
	</svg>
)

export default CardIconFour
