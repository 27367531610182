import React from 'react'
import classNames from 'classnames'
import ProductPresentationBase from '../../../components/_V2/ProductPresentationBase'

import styles from './styles.module.scss'

import { getData } from './data'

import { useLocalizationContext } from '../../../localization/useLocalizationContext'

export default function ProductPresentation() {
  const localizationContext = useLocalizationContext()

  return (
    <ProductPresentationBase
      sectionClass={styles.section}
      containerClass={styles.content}
      additionalTitleClass={localizationContext.locale !== 'ru-KZ' ? styles.title : ''}
      additionalRightColumn={styles.rightColumn}
      data={getData(localizationContext.locale)}
    />
  )
}
