import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./styles.module.scss"

const generateAltText = (name: string) => `Логотип клиента «${name}»`

export default {
	title: "С нами более 10 000 заведений ежедневно выполняют миллионы заказов",
	customers: [
		{
			name: "Металлург Магнитогорск",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/metallurg.png"}
					alt={generateAltText(name)}
					height={70}
					width={95}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Бодрый день",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/bodry.png"}
					alt={generateAltText(name)}
					height={70}
					width={100}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Флип",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/flip.png"}
					alt={generateAltText(name)}
					height={70}
					width={120}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "МГТУ им. Носова",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/mgtu.png"}
					alt={generateAltText(name)}
					height={70}
					width={60}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Еда и точка",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/eda.png"}
					alt={generateAltText(name)}
					height={70}
					width={80}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Мэд эспрессо тим",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/mad.png"}
					alt={generateAltText(name)}
					height={70}
					width={80}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "825",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/825.png"}
					alt={generateAltText(name)}
					height={70}
					width={60}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Кофедэй",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/coffeday.png"}
					alt={generateAltText(name)}
					height={70}
					width={80}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Майямми",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/yummy.png"}
					alt={generateAltText(name)}
					height={70}
					width={100}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Кафетериус",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/kafeterius.png"}
					alt={generateAltText(name)}
					height={70}
					width={130}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Бруклин бургерс",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/brooklyn.png"}
					alt={generateAltText(name)}
					height={70}
					width={60}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Матча",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/matcha.png"}
					alt={generateAltText(name)}
					height={70}
					width={100}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
	],
}
