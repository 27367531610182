import * as React from "react"
import { SVGProps } from "react"

const CardIconTwo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="150"
		height="150"
		viewBox="0 0 150 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M37.5099 62.54H29.0664C26.8573 62.54 25.0664 64.3309 25.0664 66.54V121C25.0664 123.209 26.8573 125 29.0664 125H95.7254C97.9346 125 99.7254 123.209 99.7254 121V66.54C99.7254 64.3309 97.9346 62.54 95.7254 62.54H87.2819"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M37.5117 25L43.733 31.2212L49.9542 25L56.1754 31.2212L62.3967 25L68.6179 31.2212L74.8392 25L81.0604 31.2212L87.2817 25V87.4598H37.5117V25Z"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinejoin="round"
		/>
		<path
			d="M32.5352 87.46H92.259"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M46.8438 62.575H77.9499"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M46.8438 73.0107H62.3968M77.9499 73.0107H72.6619"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M46.8438 52.621H77.9499"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M116.222 49.9793V33.1906L110.08 39.4118L103.939 33.1906L97.7969 39.4118"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M106.589 125H119.957C122.706 125 124.934 122.772 124.934 120.023V55.1724C124.934 52.4236 122.706 50.1954 119.957 50.1954H98.4824"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M106.574 95.6505H112.169"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M106.574 104.516H112.169"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M106.574 113.382H112.169"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M106.574 87.46L113.223 87.46C114.88 87.46 116.223 86.1168 116.223 84.46L116.223 65.575C116.223 63.9182 114.88 62.575 113.223 62.575L106.574 62.575"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>
)

export default CardIconTwo
