import React from 'react'

import CardIconOne from './assets/CardIconOne'
import CardIconTwo from './assets/CardIconTwo'
import CardIconThree from './assets/CardIconThree'
import CardIconFour from './assets/CardIconFour'

import styles from './styles.module.scss'
import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
  if (locale === 'ru-KZ') {
    return {
      heading: 'Ведение проекта под ключ',
      textarea: (
          <>
            <span className={styles.contentDesktop}>
              Франчайзинг — это этап развития проекта, к которому стремится
              большинство рестораторов.
              <br />
              Мы поможем настроить систему автоматизации таким образом, чтобы
              запускать новые точки было легко.
            </span>
            <span className={styles.contentTablet}>
              Франчайзинг — это этап развития проекта, к которому стремится
              большинство рестораторов.
              <br />
              Мы поможем настроить систему автоматизации таким образом, чтобы
              запускать новые точки было легко.
            </span>
            <span className={styles.contentMobile}>
              Франчайзинг — это этап развития проекта, к которому стремится
              большинство рестораторов. Мы поможем настроить систему автоматизации
              таким образом, чтобы запускать новые точки было легко.
            </span>
          </>
      ),
      cardList: [
        {
          theme: styles.grayLight,
          id: 0,
          icon: <CardIconOne />,
          heading: 'Настройка',
          content: (
              <p>
                Прозрачная работа франчайзи на каждом этапе: от поставки продуктов до
                аналитики продаж в каждой точки сети
              </p>
          ),
        },
        {
          theme: styles.grayLight,
          id: 1,
          icon: <CardIconTwo />,
          heading: 'Оборудование',
          content: (
              <p>
                Готовые комплекты оборудования на выгодных условиях для быстрого
                старта точки продаж
              </p>
          ),
        },
        {
          theme: styles.grayLight,
          id: 2,
          icon: <CardIconThree />,
          heading: 'Обучение',
          content: (
              <p>
                Занятия по автоматизации бизнеса для сети заведений: от работы склада
                и кухни до&nbsp;системы лояльности
              </p>
          ),
        },
        {
          theme: styles.grayLight,
          id: 3,
          icon: <CardIconFour />,
          heading: 'Наставник',
          content: <p>Персональный менеджер, закрепленный за сетью заведений</p>,
        },
      ],
    }
  }

  if (locale === 'kz-KZ') {
        return {
            heading: 'Жобаны дайын болғанша жүргізу',
            textarea: (
                <>
            <span className={styles.contentDesktop}>
             Франчайзинг — бұл рестораторлардың көпшілігі ұмтылатын жобаны дамыту кезеңі. <br />
                 Жаңа нүктелерді іске қосу оңай болуы үшін автоматтандыру жүйесін теңшеуге көмектесеміз.
            </span>
                    <span className={styles.contentTablet}>
              Франчайзинг — бұл рестораторлардың көпшілігі ұмтылатын жобаны дамыту кезеңі. <br />
                 Жаңа нүктелерді іске қосу оңай болуы үшін автоматтандыру жүйесін теңшеуге көмектесеміз.
            </span>
                    <span className={styles.contentMobile}>
              Франчайзинг — бұл рестораторлардың көпшілігі ұмтылатын жобаны дамыту кезеңі.
                 Жаңа нүктелерді іске қосу оңай болуы үшін автоматтандыру жүйесін теңшеуге көмектесеміз.
            </span>
                </>
            ),
            cardList: [
                {
                    theme: styles.grayLight,
                    id: 0,
                    icon: <CardIconOne />,
                    heading: 'Теңшеу',
                    content: (
                        <p>
                            Әр кезеңде франчайзидің ашық жұмысы: өнімдерді жеткізуден желінің әрбір нүктесінде сату аналитикасына дейін
                        </p>
                    ),
                },
                {
                    theme: styles.grayLight,
                    id: 1,
                    icon: <CardIconTwo />,
                    heading: 'Жабдық',
                    content: (
                        <p>
                            Сату нүктесін жылдам іске қосу үшін тиімді шарттармен жабдықтың дайын жиынтықтары
                        </p>
                    ),
                },
                {
                    theme: styles.grayLight,
                    id: 2,
                    icon: <CardIconThree />,
                    heading: 'Оқыту',
                    content: (
                        <p>
                            Мекемелер желісі үшін бизнесті автоматтандыру бойынша сабақтар: қойманың
                            жұмысынан бастап адалдық жүйесіне дейін
                        </p>
                    ),
                },
                {
                    theme: styles.grayLight,
                    id: 3,
                    icon: <CardIconFour />,
                    heading: 'Тәлімгер',
                    content: <p>Мекемелер желісіне бекітілген дербес менеджер</p>,
                },
            ],
        }
    }

  if (locale === 'ru-BY') {
    return {
      heading: 'Запуск под ключ',
      textarea: (
          <>
            <span className={styles.contentDesktop}>
              Франчайзинг — это этап развития проекта, к которому стремится
              большинство рестораторов.
              <br />
              Мы поможем настроить систему автоматизации таким образом, чтобы
              запускать новые точки было легко.
            </span>
            <span className={styles.contentTablet}>
              Франчайзинг — это этап развития проекта, к которому стремится
              большинство рестораторов.
              <br />
              Мы поможем настроить систему автоматизации таким образом, чтобы
              запускать новые точки было легко.
            </span>
            <span className={styles.contentMobile}>
                Франчайзинг — это этап развития проекта, к которому стремится
                большинство рестораторов. Мы поможем настроить систему автоматизации
                таким образом, чтобы запускать новые точки было легко.
              </span>
          </>
      ),
      cardList: [
        {
          theme: styles.grayLight,
          id: 0,
          icon: <CardIconOne />,
          heading: 'Настройка',
          content: (
              <p>
                Прозрачная работа франчайзи на каждом этапе: от поставки продуктов до
                аналитики продаж в каждой точки сети
              </p>
          ),
        },
        {
          theme: styles.grayLight,
          id: 1,
          icon: <CardIconTwo />,
          heading: 'Оборудование',
          content: (
              <p>
                Готовые комплекты оборудования на выгодных условиях для быстрого
                старта точки продаж
              </p>
          ),
        },
        {
          theme: styles.grayLight,
          id: 2,
          icon: <CardIconThree />,
          heading: 'Обучение',
          content: (
              <p>
                Занятия по автоматизации бизнеса для сети заведений: от работы склада
                и кухни до&nbsp;системы лояльности
              </p>
          ),
        },
        {
          theme: styles.grayLight,
          id: 3,
          icon: <CardIconFour />,
          heading: 'Наставник',
          content: <p>Персональный менеджер, закрепленный за сетью заведений</p>,
        },
      ],
    }
  }

  return {
    heading: 'Внедрение под ключ',
    textarea: (
        <>
      <span className={styles.contentDesktop}>
        Франчайзинг — это этап развития проекта, к которому стремится
        большинство рестораторов.
        <br />
        Мы поможем настроить систему автоматизации таким образом, чтобы
        запускать новые точки было легко.
      </span>
          <span className={styles.contentTablet}>
        Франчайзинг — это этап развития проекта, к которому стремится
        большинство рестораторов.
        <br />
        Мы поможем настроить систему автоматизации таким образом, чтобы
        запускать новые точки было легко.
      </span>
          <span className={styles.contentMobile}>
        Франчайзинг — это этап развития проекта, к которому стремится
        большинство рестораторов. Мы поможем настроить систему автоматизации
        таким образом, чтобы запускать новые точки было легко.
      </span>
        </>
    ),
    cardList: [
      {
        theme: styles.grayLight,
        id: 0,
        icon: <CardIconOne />,
        heading: 'Настройка',
        content: (
            <p>
              Прозрачная работа франчайзи на каждом этапе: от поставки продуктов до
              аналитики продаж в каждой точки сети
            </p>
        ),
      },
      {
        theme: styles.grayLight,
        id: 1,
        icon: <CardIconTwo />,
        heading: 'Оборудование',
        content: (
            <p>
              Готовые комплекты оборудования на выгодных условиях для быстрого
              старта точки продаж
            </p>
        ),
      },
      {
        theme: styles.grayLight,
        id: 2,
        icon: <CardIconThree />,
        heading: 'Обучение',
        content: (
            <p>
              Занятия по автоматизации бизнеса для сети заведений: от работы склада
              и кухни до&nbsp;системы лояльности
            </p>
        ),
      },
      {
        theme: styles.grayLight,
        id: 3,
        icon: <CardIconFour />,
        heading: 'Наставник',
        content: <p>Персональный менеджер, закрепленный за сетью заведений</p>,
      },
    ],
  }
}

