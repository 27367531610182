import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"
import classNames from "classnames";

import {Locales} from "../../../../localization/types";

export const getData = (locale: Locales) => {
	if (locale === 'ru-BY') {
		return [
			{
				subheading: "быстрый старт",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Все готово,
					<br />
					подключайся!
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>Все готово, подключайся!</span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Все готово,
					<br />
					подключайся!
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Новый партнер получит готовый к работе личный
					<br />
					кабинет. В нем уже заполнены техкарты, составлено
					<br />
					меню, доступна настройка блюд для региона.
				</span>
						<span className={styles.contentTablet}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
						<span className={styles.contentMobile}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image1}>
						<StaticImage
							src="./assets/card1/franchise-card-d-BY.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card1/franchise-card-t-BY.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card1/franchise-card-m-BY.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop1,
				theme: "greenLight",
				reverse: false,
			},
			{
				subheading: "контроль",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Анализ работы <br/> каждой точки
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>
					Анализ работы каждой точки
				</span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Анализ работы <br/> каждой точки
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Анализируй показатели каждого франчайзи. Следить
					<br />
					за топ-продажами, эффективностью акций
					<br />
					и показателями прибыли можно дистанционно,
					<br />
					в аккаунте владельца франшизы.
				</span>
						<span className={styles.contentTablet}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
						<span className={styles.contentMobile}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image2}>
						<StaticImage
							src="./assets/card2/franchise-card-d-BY.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card2/franchise-card-t-BY.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card2/franchise-card-m-BY.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop2,
				theme: "cyanLight",
				reverse: true,
			},
			{
				subheading: "система лояльности",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Единая CRM <br/> для всей сети
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Единая CRM для всей сети</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Единая CRM <br/> для всей сети
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Quick Resto безбарьерно подключает нового
					<br />
					франчайзи к единой базе гостей сети. Так новая точка
					<br />
					получает трафик, а гости все также могут накапливать
					<br />
					и тратить бонусные баллы, пользоваться
					<br />
					персональными скидками и спецпредложениями.
				</span>
						<span className={styles.contentTablet}>
					Quick Resto безбарьерно подключает нового франчайзи к единой базе
					гостей сети. Так новая точка получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться персональными
					скидками и спецпредложениями.
				</span>
						<span className={styles.contentMobile}>
					Quick Resto безбарьерно подключает нового франчайзи к единой базе
					гостей сети. Так новая точка получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться персональными
					скидками и спецпредложениями.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image3}>
						<StaticImage
							src="./assets/card3/franchise-card-d-BY.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card3/franchise-card-t-BY.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card3/franchise-card-m-BY.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop3,
				theme: "blueSkyLight",
				reverse: false,
			},
			{
				subheading: "Программа быстрого старта",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Запускаем сеть
					<br />
					за 1 день
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>Запускаем сеть за 1 день</span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Запускаем сеть
					<br />
					за 1 день
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Помогаем осуществить быструю миграцию с любой
					<br />
					системы или запускаем с нуля. Предлагаем гибкие
					<br />
					условия для владельцев франшиз и сетей.
				</span>
						<span className={styles.contentTablet}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
						<span className={styles.contentMobile}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image4}>
						<StaticImage
							src="./assets/card4/franchise-card-d-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card4/franchise-card-t-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card4/franchise-card-m-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop4,
				theme: "purpleLight",
				reverse: true,
			},
		]
	}

	if (locale === 'kz-KZ') {
		return  [
			{
				subheading: "жылдам бастау",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Бәрі теңшеліп қойған, <br /> қосылыңыз!
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>Бәрі теңшеліп қойған, қосылыңыз!</span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Бәрі теңшеліп қойған, қосылыңыз!
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Жаңа серіктес жұмысқа дайын жеке кабинетті алады.
					Онда техкарталар толтырылып қойған, мәзір жасалған, аймақ үшін тағамдарды теңшеу қолжетімді.
				</span>
						<span className={styles.contentTablet}>
					Жаңа серіктес жұмысқа дайын жеке кабинетті алады.
					Онда техкарталар толтырылып қойған, мәзір жасалған, аймақ үшін тағамдарды теңшеу қолжетімді.
				</span>
						<span className={styles.contentMobile}>
					Жаңа серіктес жұмысқа дайын жеке кабинетті алады.
					Онда техкарталар толтырылып қойған, мәзір жасалған, аймақ үшін тағамдарды теңшеу қолжетімді.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image1}>
						<StaticImage
							src="./assets/card1/franchise-card-d-KZ.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card1/franchise-card-t-KZ.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card1/franchise-card-m-KZ.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop1,
				theme: "greenLight",
				reverse: false,
			},
			{
				subheading: "бақылау",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Әр нүкте бойынша <br/> жұмыс деректері
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>
					Әр нүкте бойынша жұмыс деректері
				</span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Әр нүкте бойынша жұмыс деректері
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Әр франчайзидің көрсеткіштерін талдаңыз. Үздік сатуды, акциялардың тиімділігін және пайда
					көрсеткіштерін қашықтан, франшиза иесінің аккаунтында қадағалаңыз.
				</span>
						<span className={styles.contentTablet}>
					Әр франчайзидің көрсеткіштерін талдаңыз. Үздік сатуды, акциялардың тиімділігін және пайда
					көрсеткіштерін қашықтан, франшиза иесінің аккаунтында қадағалаңыз.
				</span>
						<span className={styles.contentMobile}>
					Әр франчайзидің көрсеткіштерін талдаңыз. Үздік сатуды, акциялардың тиімділігін және пайда
					көрсеткіштерін қашықтан, франшиза иесінің аккаунтында қадағалаңыз.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image2}>
						<StaticImage
							src="./assets/card2/franchise-card-d-KZ.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card2/franchise-card-t-KZ.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card2/franchise-card-m-KZ.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop2,
				theme: "cyanLight",
				reverse: true,
			},
			{
				subheading: "адалдық жүйесі",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Бүкіл желі үшін <br />бірыңғай CRM
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>Бүкіл желі үшін бірыңғай CRM </span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Бүкіл желі үшін <br />бірыңғай CRM
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Quick Resto желі қонақтарының бірыңғай қорына жаңа франчайзиді кедергісіз қосады. Осылайша жаңа нүкте
					трафик алады, ал қонақтардың бәрі бонустық баллдарды жинақтап, жарата алады, дербес жеңілдіктерді
					және арнайы ұсыныстарды пайдалана алады.
				</span>
						<span className={styles.contentTablet}>
					Quick Resto желі қонақтарының бірыңғай қорына жаңа франчайзиді кедергісіз қосады. Осылайша жаңа нүкте трафик алады,
							ал қонақтардың бәрі бонустық баллдарды жинақтап, жарата алады, дербес жеңілдіктерді және арнайы ұсыныстарды пайдалана алады.
				</span>
						<span className={styles.contentMobile}>
					Quick Resto желі қонақтарының бірыңғай қорына жаңа франчайзиді кедергісіз қосады. Осылайша жаңа нүкте трафик алады, ал қонақтардың
							бәрі бонустық баллдарды жинақтап, жарата алады, дербес жеңілдіктерді және арнайы ұсыныстарды пайдалана алады.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image3}>
						<StaticImage
							src="./assets/card3/franchise-card-d-KZ.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card3/franchise-card-t-KZ.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card3/franchise-card-m-KZ.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop3,
				theme: "blueSkyLight",
				reverse: false,
			},
			{
				subheading: "Жылдам бастау бағдарламасы",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Желіні 1 күн <br />ішінде жылдам іске қосу
				</span>
						<span className={classNames(styles.contentTablet, styles.heading)}>Желіні 1 күн ішінде жылдам іске қосу</span>
						<span className={classNames(styles.contentMobile, styles.heading)}>
					Желіні 1 күн ішінде жылдам іске қосу
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Кез келген жүйеден жылдам көшуді жүзеге асыруға көмектесеміз немесе басынан бастап іске қосамыз. Франшизалар мен желілер иелері үшін икемді жағдайлар ұсынамыз.
				</span>
						<span className={styles.contentTablet}>
					Кез келген жүйеден жылдам көшуді жүзеге асыруға көмектесеміз немесе басынан бастап іске қосамыз. Франшизалар мен желілер иелері үшін икемді жағдайлар ұсынамыз.
				</span>
						<span className={styles.contentMobile}>
					Кез келген жүйеден жылдам көшуді жүзеге асыруға көмектесеміз немесе басынан бастап іске қосамыз. Франшизалар мен желілер иелері үшін икемді жағдайлар ұсынамыз.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image4}>
						<StaticImage
							src="./assets/card4/franchise-card-d-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card4/franchise-card-t-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card4/franchise-card-m-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop4,
				theme: "purpleLight",
				reverse: true,
			},
		]
	}

	if (locale === 'ru-KZ') {
		return  [
			{
				subheading: "быстрый старт",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Всё уже настроено,<br/> подключайся!
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Всё уже настроено, подключайся!</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Всё уже <br/> настроено,<br/> подключайся!
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Новый партнер получит готовый к работе личный
					<br />
					кабинет. В нем уже заполнены техкарты, составлено
					<br />
					меню, доступна настройка блюд для региона.
				</span>
						<span className={styles.contentTablet}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
						<span className={styles.contentMobile}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image1}>
						<StaticImage
							src="./assets/card1/franchise-card-d-KZ.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card1/franchise-card-t-KZ.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card1/franchise-card-m-KZ.png"
							alt="Программа автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop1,
				theme: "greenLight",
				reverse: false,
			},
			{
				subheading: "контроль",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Детали работы <br/> по каждой точке
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>
					Детали работы по каждой точке
				</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Детали работы <br/> по каждой <br/> точке
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Анализируй показатели каждого франчайзи. Следить
					<br />
					за топ-продажами, эффективностью акций
					<br />
					и показателями прибыли можно дистанционно,
					<br />
					в аккаунте владельца франшизы.
				</span>
						<span className={styles.contentTablet}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
						<span className={styles.contentMobile}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image2}>
						<StaticImage
							src="./assets/card2/franchise-card-d-KZ.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card2/franchise-card-t-KZ.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card2/franchise-card-m-KZ.png"
							alt="Отчеты по продажам франчайзи"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop2,
				theme: "cyanLight",
				reverse: true,
			},
			{
				subheading: "система лояльности",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Единая CRM <br/> для всей сети
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Единая CRM для всей сети</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Единая CRM <br/> для всей сети
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Quick Resto безбарьерно подключает нового <br/>
					франчайзи к единой базе гостей. Так новая точка <br/>
					получает трафик, а клиенты всё так же могут <br/>
					накапливать и тратить бонусные баллы, пользоваться <br/>
					персональными скидками и спецпредложениями.
				</span>
				<span className={styles.contentTablet}>
					Quick Resto безбарьерно подключает нового
					франчайзи к единой базе гостей сети. Так новая точка
					получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться
					персональными скидками и спецпредложениями.
				</span>
				<span className={styles.contentMobile}>
					Quick Resto безбарьерно подключает нового
					франчайзи к единой базе гостей сети. Так новая точка
					получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться
					персональными скидками и спецпредложениями.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image3}>
						<StaticImage
							src="./assets/card3/franchise-card-d-KZ.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card3/franchise-card-t-KZ.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card3/franchise-card-m-KZ.png"
							alt="crm для франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop3,
				theme: "blueSkyLight",
				reverse: false,
			},
			{
				subheading: "Программа быстрого старта",
				heading: (
					<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Быстрый запуск <br/> сети за 1 день
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Быстрый запуск сети за 1 день</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Быстрый <br/> запуск сети <br/> за 1 день
				</span>
					</>
				),
				textarea: (
					<>
				<span className={styles.contentDesktop}>
					Помогаем осуществить быструю миграцию с любой
					<br />
					системы или запускаем с нуля. Предлагаем гибкие
					<br />
					условия для владельцев франшиз и сетей.
				</span>
				<span className={styles.contentTablet}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
				<span className={styles.contentMobile}>
					Помогаем осуществить быструю миграцию с любой системы или&nbsp;запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
					</>
				),
				mediaContent: (
					<div className={styles.image4}>
						<StaticImage
							src="./assets/card4/franchise-card-d-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card4/franchise-card-t-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
						<StaticImage
							src="./assets/card4/franchise-card-m-BY.png"
							alt="Cистема автоматизации франшизы"
							objectFit="contain"
							placeholder="blurred"
							quality={90}
						/>
					</div>
				),
				backdrop: styles.backdrop4,
				theme: "purpleLight",
				reverse: true,
			},
		]
	}

	return [
		{
			subheading: "быстрый старт",
			heading: (
				<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Все готово,
					<br />
					подключайся!
				</span>
					<span className={classNames(styles.contentTablet, styles.heading)}>Все готово, подключайся!</span>
					<span className={classNames(styles.contentMobile, styles.heading)}>
					Все готово,
					<br />
					подключайся!
				</span>
				</>
			),
			textarea: (
				<>
				<span className={styles.contentDesktop}>
					Новый партнер получит готовый к работе личный
					<br />
					кабинет. В нем уже заполнены техкарты, составлено
					<br />
					меню, доступна настройка блюд для региона.
				</span>
					<span className={styles.contentTablet}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
					<span className={styles.contentMobile}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
				</>
			),
			mediaContent: (
				<div className={styles.image1}>
					<StaticImage
						src="./assets/card1/franchise-card-d.png"
						alt="Программа автоматизации франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card1/franchise-card-t.png"
						alt="Программа автоматизации франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card1/franchise-card-m.png"
						alt="Программа автоматизации франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			backdrop: styles.backdrop1,
			theme: "greenLight",
			reverse: false,
		},
		{
			subheading: "контроль",
			heading: (
				<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Отчеты и показатели
					<br />
					каждой точки
				</span>
					<span className={classNames(styles.contentTablet, styles.heading)}>
					Отчеты и показатели каждой точки
				</span>
					<span className={classNames(styles.contentMobile, styles.heading)}>
					Отчеты
					<br />
					и показатели
					<br />
					каждой точки
				</span>
				</>
			),
			textarea: (
				<>
				<span className={styles.contentDesktop}>
					Анализируй показатели каждого франчайзи. Следить
					<br />
					за топ-продажами, эффективностью акций
					<br />
					и показателями прибыли можно дистанционно,
					<br />
					в аккаунте владельца франшизы.
				</span>
					<span className={styles.contentTablet}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
					<span className={styles.contentMobile}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
				</>
			),
			mediaContent: (
				<div className={styles.image2}>
					<StaticImage
						src="./assets/card2/franchise-card-d.png"
						alt="Отчеты по продажам франчайзи"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card2/franchise-card-t.png"
						alt="Отчеты по продажам франчайзи"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card2/franchise-card-m.png"
						alt="Отчеты по продажам франчайзи"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			backdrop: styles.backdrop2,
			theme: "cyanLight",
			reverse: true,
		},
		{
			subheading: "система лояльности",
			heading: (
				<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Одна CRM
					<br />
					для всей сети
				</span>
					<span className={classNames(styles.contentTablet, styles.heading)}>Одна CRM для всей сети</span>
					<span className={classNames(styles.contentMobile, styles.heading)}>
					Одна CRM
					<br />
					для всей сети
				</span>
				</>
			),
			textarea: (
				<>
				<span className={styles.contentDesktop}>
					Quick Resto безбарьерно подключает нового
					<br />
					франчайзи к единой базе гостей сети. Так новая точка
					<br />
					получает трафик, а гости все также могут накапливать
					<br />
					и тратить бонусные баллы, пользоваться
					<br />
					персональными скидками и спецпредложениями.
				</span>
					<span className={styles.contentTablet}>
					Quick Resto безбарьерно подключает нового франчайзи к единой базе
					гостей сети. Так новая точка получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться персональными
					скидками и спецпредложениями.
				</span>
					<span className={styles.contentMobile}>
					Quick Resto безбарьерно подключает нового франчайзи к единой базе
					гостей сети. Так новая точка получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться персональными
					скидками и спецпредложениями.
				</span>
				</>
			),
			mediaContent: (
				<div className={styles.image3}>
					<StaticImage
						src="./assets/card3/franchise-card-d.png"
						alt="crm для франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card3/franchise-card-t.png"
						alt="crm для франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card3/franchise-card-m.png"
						alt="crm для франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			backdrop: styles.backdrop3,
			theme: "blueSkyLight",
			reverse: false,
		},
		{
			subheading: "Программа быстрого старта",
			heading: (
				<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Запускаем сеть
					<br />
					за 1 день
				</span>
					<span className={classNames(styles.contentTablet, styles.heading)}>Запускаем сеть за 1 день</span>
					<span className={classNames(styles.contentMobile, styles.heading)}>
					Запускаем сеть
					<br />
					за 1 день
				</span>
				</>
			),
			textarea: (
				<>
				<span className={styles.contentDesktop}>
					Помогаем осуществить быструю миграцию с любой
					<br />
					системы или запускаем с нуля. Предлагаем гибкие
					<br />
					условия для владельцев франшиз и сетей.
				</span>
					<span className={styles.contentTablet}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
					<span className={styles.contentMobile}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
				</>
			),
			mediaContent: (
				<div className={styles.image4}>
					<StaticImage
						src="./assets/card4/franchise-card-d.png"
						alt="Cистема автоматизации франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card4/franchise-card-t.png"
						alt="Cистема автоматизации франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
					<StaticImage
						src="./assets/card4/franchise-card-m.png"
						alt="Cистема автоматизации франшизы"
						objectFit="contain"
						placeholder="blurred"
						quality={90}
					/>
				</div>
			),
			backdrop: styles.backdrop4,
			theme: "purpleLight",
			reverse: true,
		},
	]
}

export default [
	{
		subheading: "быстрый старт",
		heading: (
			<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Все готово,
					<br />
					подключайся!
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Все готово, подключайся!</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Все готово,
					<br />
					подключайся!
				</span>
			</>
		),
		textarea: (
			<>
				<span className={styles.contentDesktop}>
					Новый партнер получит готовый к работе личный
					<br />
					кабинет. В нем уже заполнены техкарты, составлено
					<br />
					меню, доступна настройка блюд для региона.
				</span>
				<span className={styles.contentTablet}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
				<span className={styles.contentMobile}>
					Новый партнер получит готовый к работе личный кабинет. В нем уже
					заполнены техкарты, составлено меню, доступна настройка блюд для
					региона.
				</span>
			</>
		),
		mediaContent: (
			<div className={styles.image1}>
				<StaticImage
					src="./assets/card1/franchise-card-d.png"
					alt="Программа автоматизации франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card1/franchise-card-t.png"
					alt="Программа автоматизации франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card1/franchise-card-m.png"
					alt="Программа автоматизации франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</div>
		),
		backdrop: styles.backdrop1,
		theme: "greenLight",
		reverse: false,
	},
	{
		subheading: "контроль",
		heading: (
			<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Отчеты и показатели
					<br />
					каждой точки
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>
					Отчеты и показатели каждой точки
				</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Отчеты
					<br />
					и показатели
					<br />
					каждой точки
				</span>
			</>
		),
		textarea: (
			<>
				<span className={styles.contentDesktop}>
					Анализируй показатели каждого франчайзи. Следить
					<br />
					за топ-продажами, эффективностью акций
					<br />
					и показателями прибыли можно дистанционно,
					<br />
					в аккаунте владельца франшизы.
				</span>
				<span className={styles.contentTablet}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
				<span className={styles.contentMobile}>
					Анализируй показатели каждого франчайзи. Следить за топ-продажами,
					эффективностью акций и показателями прибыли можно дистанционно,
					в аккаунте владельца франшизы.
				</span>
			</>
		),
		mediaContent: (
			<div className={styles.image2}>
				<StaticImage
					src="./assets/card2/franchise-card-d.png"
					alt="Отчеты по продажам франчайзи"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card2/franchise-card-t.png"
					alt="Отчеты по продажам франчайзи"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card2/franchise-card-m.png"
					alt="Отчеты по продажам франчайзи"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</div>
		),
		backdrop: styles.backdrop2,
		theme: "cyanLight",
		reverse: true,
	},
	{
		subheading: "система лояльности",
		heading: (
			<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Одна CRM
					<br />
					для всей сети
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Одна CRM для всей сети</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Одна CRM
					<br />
					для всей сети
				</span>
			</>
		),
		textarea: (
			<>
				<span className={styles.contentDesktop}>
					Quick Resto безбарьерно подключает нового
					<br />
					франчайзи к единой базе гостей сети. Так новая точка
					<br />
					получает трафик, а гости все также могут накапливать
					<br />
					и тратить бонусные баллы, пользоваться
					<br />
					персональными скидками и спецпредложениями.
				</span>
				<span className={styles.contentTablet}>
					Quick Resto безбарьерно подключает нового франчайзи к единой базе
					гостей сети. Так новая точка получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться персональными
					скидками и спецпредложениями.
				</span>
				<span className={styles.contentMobile}>
					Quick Resto безбарьерно подключает нового франчайзи к единой базе
					гостей сети. Так новая точка получает трафик, а гости все также могут
					накапливать и тратить бонусные баллы, пользоваться персональными
					скидками и спецпредложениями.
				</span>
			</>
		),
		mediaContent: (
			<div className={styles.image3}>
				<StaticImage
					src="./assets/card3/franchise-card-d.png"
					alt="crm для франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card3/franchise-card-t.png"
					alt="crm для франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card3/franchise-card-m.png"
					alt="crm для франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</div>
		),
		backdrop: styles.backdrop3,
		theme: "blueSkyLight",
		reverse: false,
	},
	{
		subheading: "Программа быстрого старта",
		heading: (
			<>
				<span className={classNames(styles.contentDesktop, styles.heading)}>
					Запускаем сеть
					<br />
					за 1 день
				</span>
				<span className={classNames(styles.contentTablet, styles.heading)}>Запускаем сеть за 1 день</span>
				<span className={classNames(styles.contentMobile, styles.heading)}>
					Запускаем сеть
					<br />
					за 1 день
				</span>
			</>
		),
		textarea: (
			<>
				<span className={styles.contentDesktop}>
					Помогаем осуществить быструю миграцию с любой
					<br />
					системы или запускаем с нуля. Предлагаем гибкие
					<br />
					условия для владельцев франшиз и сетей.
				</span>
				<span className={styles.contentTablet}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
				<span className={styles.contentMobile}>
					Помогаем осуществить быструю миграцию с любой системы или запускаем с
					нуля. Предлагаем гибкие условия для владельцев франшиз и сетей.
				</span>
			</>
		),
		mediaContent: (
			<div className={styles.image4}>
				<StaticImage
					src="./assets/card4/franchise-card-d.png"
					alt="Cистема автоматизации франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card4/franchise-card-t.png"
					alt="Cистема автоматизации франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
				<StaticImage
					src="./assets/card4/franchise-card-m.png"
					alt="Cистема автоматизации франшизы"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			</div>
		),
		backdrop: styles.backdrop4,
		theme: "purpleLight",
		reverse: true,
	},
]
