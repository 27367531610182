import * as React from "react"
import { SVGProps } from "react"

const CardIconOne = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="150"
		height="150"
		viewBox="0 0 150 150"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M44.5524 69.3931L27.2862 52.1269C25.5713 50.412 25.5713 47.6316 27.2862 45.9167L45.9167 27.2862C47.6316 25.5713 50.412 25.5713 52.1269 27.2862L74.7888 49.948"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M57.5176 33.0259L45.0972 45.4463"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M66.1758 41.6835L53.7554 54.1039"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M112.054 62.4388L94.2001 44.5845M34.7714 120.079L55.7308 117.75C56.7218 117.64 57.6459 117.196 58.3509 116.491L121.669 53.1732C123.384 51.4583 123.384 48.6779 121.669 46.963L103.038 28.3325C101.323 26.6176 98.543 26.6176 96.8281 28.3325L33.5102 91.6504C32.8051 92.3555 32.361 93.2795 32.2509 94.2706L29.9221 115.23C29.6113 118.027 31.9746 120.39 34.7714 120.079Z"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M80.6069 105.448L97.8731 122.714C99.588 124.429 102.368 124.429 104.083 122.714L122.714 104.083C124.429 102.368 124.429 99.5879 122.714 97.873L100.052 75.2111"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M116.973 92.4822L104.552 104.903"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
		<path
			d="M108.316 83.8251L95.896 96.2454"
			stroke="#3021E5"
			strokeWidth="3"
			strokeLinecap="round"
		/>
	</svg>
)

export default CardIconOne
