import React from "react"

import { FeatureGalleryItem } from "../../../components/_V2/FeaturesGallery"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

import mediaContent from "./mediaContent.module.scss"
import MobileAppBackdropIcon from "../../../assets/images/mobileFeatures/MobileAppBackdropIcon"
import backdropIconsStyles from "./backdropIconsStyles.module.scss"
import WebsiteBackdropIcon from "../../../assets/images/mobileFeatures/WebsiteBackdropIcon"

import { Locales } from "../../../localization/types";

export const getData = (locale: Locales):{
	features: Array<FeatureGalleryItem>
} => {
	if (locale === 'ru-BY') {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: (
						<>
							Готовый канал <br/>
							для старта продаж
						</>
					),
					tabletTitle: "Готовый канал для старта продаж",
					mobileTitle: "Готовый канал для старта продаж",
					desktopContent: (
						<p>
							Франчайзи получает мобильное приложение. Быстро, в фирменном стиле и без найма разработчиков. Клиенты смогут пользоваться бонусной программой и делать заказы на доставку.
						</p>
					),
					tabletContent: (
						<p>
							Франчайзи получает мобильное приложение. Быстро, в фирменном стиле и без найма разработчиков. Клиенты смогут пользоваться бонусной программой и делать заказы на доставку.						</p>
					),
					mobileContent: (
						<p>
							Франчайзи получает мобильное приложение. Быстро, в фирменном стиле и без найма разработчиков. Клиенты смогут пользоваться бонусной программой и делать заказы на доставку.						</p>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_by.png"
								alt={"мобильное приложение для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: (
						<>
							Ещё больше заказов — через собственный сайт
						</>
					),
					tabletTitle: "Ещё больше заказов — через собственный сайт",
					mobileTitle: "Ещё больше заказов — через собственный сайт",
					desktopContent: (
						<p>
							Заказы с сайта отображаются на кассовом терминале франчайзи. Доступна интеграция с сервисами доставки и подключение модуля доставки для работы со своими курьерами.
						</p>
					),
					tabletContent: (
						<p>
							Заказы с сайта отображаются на кассовом терминале франчайзи. Доступна интеграция с сервисами доставки и подключение модуля доставки для работы со своими курьерами.
						</p>
					),
					mobileContent: (
						<p>
							Заказы с сайта отображаются на кассовом терминале франчайзи. Доступна интеграция с сервисами доставки и подключение модуля доставки для работы со своими курьерами.
						</p>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_by.png"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'kz-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Қосымша",
					desktopTitle: (
						<>
							Сатуды бастау <br />үшін дайын арна
						</>
					),
					tabletTitle: "Сатуды бастау үшін дайын арна",
					mobileTitle: "Сатуды бастау үшін дайын арна",
					desktopContent: (
						<p>
							Франчайзи мобильді қосымшаны алады. Жылдам, фирмалық стильде және әзірлеушілерді жалдамай. Қонақтар бонустық бағдарламаны пайдаланып, жеткізуге тапсырыстарды жасай алады.
						</p>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: (
						<>
							Одан да көбірек тапсырыстар - <br />сайт арқылы
						</>
					),
					tabletTitle: "Одан да көбірек тапсырыстар - сайт арқылы",
					mobileTitle: "Одан да көбірек тапсырыстар - сайт арқылы",
					desktopContent: (
						<p>
							Сайттан келіп түсетін тапсырыстар франчайзидің кассалық терминалында көрсетіледі. Жеткізу сервистерімен интеграция және өз курьерлерімен жұмыс істеу үшін жеткізу модулін қосу қолжетімді.
						</p>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	if (locale === 'ru-KZ') {
		return {
			features: [
				{
					desktopContentWidth: 660,
					tabName: "Приложение",
					desktopTitle: (
						<>
							Готовый канал <br/>
							для начала продаж
						</>
					),
					tabletTitle: "Готовый канал для начала продаж",
					mobileTitle: "Готовый канал для начала продаж",
					desktopContent: (
						<p>
							Франчайзи получает мобильное приложение. Быстро, в фирменном стиле и без найма разработчиков. Гости смогут пользоваться бонусной программой и делать заказы на доставку.
						</p>
					),
					tabletContent: (
						<p>
							Франчайзи получает мобильное приложение. Быстро, в фирменном стиле и без найма разработчиков. Гости смогут пользоваться бонусной программой и делать заказы на доставку.
						</p>
					),
					mobileContent: (
						<p>
							Франчайзи получает мобильное приложение. Быстро, в фирменном стиле и без найма разработчиков. Гости смогут пользоваться бонусной программой и делать заказы на доставку.
						</p>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container1}>
							<StaticImage
								className={mediaContent.image1}
								src="../../../assets/images/mobileFeatures/phone_kz.png"
								alt={"мобильное приложение для кафе"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<MobileAppBackdropIcon
							className={backdropIconsStyles.mobileAppBackdropIcon}
						/>
					),
				},
				{
					desktopContentWidth: 600,
					tabName: "Сайт",
					desktopTitle: (
						<>
							Ещё больше заказов — <br/> через сайт
						</>
					),
					tabletTitle: "Ещё больше заказов — через сайт",
					mobileTitle: "Ещё больше заказов — через сайт",
					desktopContent: (
						<p>
							Заказы с сайта отображаются на кассовом терминале франчайзи. Доступна интеграция с сервисами доставки и подключение модуля доставки для работы со своими курьерами.
						</p>
					),
					tabletContent: (
						<p>
							Заказы с сайта отображаются на кассовом терминале франчайзи. Доступна интеграция с сервисами доставки и подключение модуля доставки для работы со своими курьерами.
						</p>
					),
					mobileContent: (
						<p>
							Заказы с сайта отображаются на кассовом терминале франчайзи. Доступна интеграция с сервисами доставки и подключение модуля доставки для работы со своими курьерами.
						</p>
					),
					detailLink: pagesLinks.indexWL,
					mediaContent: (
						<div className={mediaContent.image__container2}>
							<StaticImage
								className={mediaContent.image2}
								src="../../../assets/images/mobileFeatures/shop-feature_kz.webp"
								alt={"Сайт для ресторана"}
								objectFit={"contain"}
								placeholder={"blurred"}
								quality={90}
							/>
						</div>
					),
					backdropContent: (
						<WebsiteBackdropIcon
							className={backdropIconsStyles.websiteBackdropIcon}
						/>
					),
				},
			] as Array<FeatureGalleryItem>,
		}
	}

	return {
		features: [
			{
				desktopContentWidth: 660,
				tabName: "Приложение",
				desktopTitle: (
					<>
						Готовый канал продаж<br />
						для старта франшизы
					</>
				),
				tabletTitle: "Готовый канал продаж для старта франшизы",
				mobileTitle: "Готовый канал продаж для старта франшизы",
				desktopContent: (
					<p>
						Франчайзи получает мобильное приложение. Быстро,<br />
						в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут<br />
						пользоваться бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
					</p>
				),
				tabletContent: (
					<p>
						Франчайзи получает мобильное приложение. Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
					</p>
				),
				mobileContent: (
					<p>
						Франчайзи получает мобильное приложение. Быстро, в&nbsp;фирменном стиле и&nbsp;без найма разработчиков. Клиенты смогут пользоваться бонусной программой и&nbsp;делать заказы на&nbsp;доставку.
					</p>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container1}>
						<StaticImage
							className={mediaContent.image1}
							src="../../../assets/images/mobileFeatures/phone.png"
							alt={"мобильное приложение для кафе"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<MobileAppBackdropIcon
						className={backdropIconsStyles.mobileAppBackdropIcon}
					/>
				),
			},
			{
				desktopContentWidth: 600,
				tabName: "Сайт",
				desktopTitle: (
					<>
						А ещё больше —<br />
						на полноценном сайте
					</>
				),
				tabletTitle: "А ещё больше — на полноценном сайте",
				mobileTitle: "А ещё больше — на полноценном сайте",
				desktopContent: (
					<p>
						Заказы с&nbsp;сайта отображаются на&nbsp;кассовом терминале франчайзи.<br />
						Доступна интеграция с&nbsp;сервисами доставки и&nbsp;подключение модуля<br />
						доставки для работы со&nbsp;своими курьерами.
					</p>
				),
				tabletContent: (
					<p>
						Заказы с&nbsp;сайта отображаются на&nbsp;кассовом терминале франчайзи. Доступна интеграция с&nbsp;сервисами доставки и&nbsp;подключение модуля доставки для работы со&nbsp;своими курьерами.
					</p>
				),
				mobileContent: (
					<p>
						Заказы с&nbsp;сайта отображаются на&nbsp;кассовом терминале франчайзи. Доступна интеграция с&nbsp;сервисами доставки и&nbsp;подключение модуля доставки для работы со&nbsp;своими курьерами.
					</p>
				),
				detailLink: pagesLinks.indexWL,
				mediaContent: (
					<div className={mediaContent.image__container2}>
						<StaticImage
							className={mediaContent.image2}
							src="../../../assets/images/mobileFeatures/shop-feature.webp"
							alt={"Сайт для ресторана"}
							objectFit={"contain"}
							placeholder={"blurred"}
							quality={90}
						/>
					</div>
				),
				backdropContent: (
					<WebsiteBackdropIcon
						className={backdropIconsStyles.websiteBackdropIcon}
					/>
				),
			},
		] as Array<FeatureGalleryItem>,
	}
}
