import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import styles from './styles.module.scss'
import { Locales } from "../../../localization/types"

export const getData = (locale: Locales) => {
    if (locale === 'ru-BY') {
        return [
            {
                category: 'консультации',
                title: 'Забронируй встречу с экспертом',
                description: (
                    <p>
                        Обучение через разбор кейсов и настройка отчётов для&nbsp;оценки рентабельности сети заведений.
                    </p>
                ),
                quote: (
                    <>
                        Создай сеть и продавай франшизу.
                        <br />
                        Посвяти время бизнесу!
                    </>
                ),
                img: (
                    <StaticImage
                        className={styles.consultationImage}
                        src="./assets/consultation.png"
                        alt="Алексей Гаврилов"
                        objectFit="contain"
                        objectPosition="top"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                className: styles.consultationFeature,
                theme: 'grayLight',
                actionText: 'Отправить заявку',
                actionLink: '#popup',
                actionClass: styles.consultationAction,
            },
        ]
    }

    if (locale === 'kz-KZ') {
        return [
            {
                category: 'консультациялар',
                title: 'Сарапшымен кездесуді тағайындаңыз',
                description: (
                    <p>
                        Кейстерді талдау арқылы оқыту, мекемелер желісінің табыстылығын бағалау үшін есептерді теңшеу.
                    </p>
                ),
                quote: (
                    <>
                        Желіні құрып, франшизаны сатыңыз.
                        <br />
                        Бизнеске уақытты бөліңіз!
                    </>
                ),
                img: (
                    <StaticImage
                        className={styles.consultationImage}
                        src="./assets/consultation.png"
                        alt="Алексей Гаврилов"
                        objectFit="contain"
                        objectPosition="top"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                className: styles.consultationFeature,
                theme: 'grayLight',
                actionText: 'Өтінім жіберу',
                actionLink: '#popup',
                actionClass: styles.consultationAction,
            },
        ]
    }

    if (locale === 'ru-KZ') {
        return [
            {
                category: 'консультации',
                title: 'Назначь встречу с экспертом',
                description: (
                    <p>
                        Обучение через разбор кейсов и настройка отчётов для&nbsp;оценки рентабельности сети заведений.
                    </p>
                ),
                quote: (
                    <>
                        Создай сеть и продавай франшизу.
                        <br />
                        Посвяти время бизнесу!
                    </>
                ),
                img: (
                    <StaticImage
                        className={styles.consultationImage}
                        src="./assets/consultation.png"
                        alt="Алексей Гаврилов"
                        objectFit="contain"
                        objectPosition="top"
                        placeholder="blurred"
                        quality={90}
                    />
                ),

                className: styles.consultationFeature,
                theme: 'grayLight',
                actionText: 'Отправить заявку',
                actionLink: '#popup',
                actionClass: styles.consultationAction,
            },
        ]
    }

    return [
        {
            category: 'консультации',
            title: 'Забронируй встречу с экспертом',
            description: (
                <p>
                    Обучение через разбор кейсов, настройка отчётов для&nbsp;оценки
                    рентабельности системы лояльности.
                </p>
            ),
            quote: (
                <>
                    Создай сеть и продавай франшизу.
                    <br />
                    Посвяти время бизнесу!
                </>
            ),
            img: (
                <StaticImage
                    className={styles.consultationImage}
                    src="./assets/consultation.png"
                    alt="Алексей Гаврилов"
                    objectFit="contain"
                    objectPosition="top"
                    placeholder="blurred"
                    quality={90}
                />
            ),

            className: styles.consultationFeature,
            theme: 'grayLight',
            actionText: 'Отправить заявку',
            actionLink: '#popup',
            actionClass: styles.consultationAction,
        },
    ]
}
