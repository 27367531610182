import React from "react"

import { getData } from "./data"
import SimpleFeatures from "../../../components/_V2/SimpleFeatures"
import {useLocalizationContext} from "../../../localization/useLocalizationContext";


interface Props {
	className?: string
}

export default function ConsultationFeature(props: Props) {
	const localizationContext = useLocalizationContext();
	const data = getData(localizationContext.locale);

	return <SimpleFeatures data={data} className={props.className} />
}
