import * as React from "react"
import { SVGProps } from "react"

const WeighterIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M12.0059 17.7866V3.0625"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M6 5.06225L18 5.06226"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M5 20.7861L19 20.7861"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M21 13.0625L18 5.0625L15 13.0625"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M8.99414 13.0625L5.99414 5.0625L2.99414 13.0625"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinejoin="round"
		/>
		<path
			d="M21.0117 13.0625C21.0117 14.7194 19.6686 16.0625 18.0117 16.0625C16.3549 16.0625 15.0117 14.7194 15.0117 13.0625"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M9.00586 13.0625C9.00586 14.7194 7.66271 16.0625 6.00586 16.0625C4.34901 16.0625 3.00586 14.7194 3.00586 13.0625"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export default WeighterIcon
