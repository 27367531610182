import * as React from "react"
import { SVGProps } from "react"

const CircleAndSquare = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M8.36328 13.87C8.36328 11.9382 8.36328 10.9706 8.73739 10.2323C9.0646 9.5823 9.58728 9.05359 10.2305 8.72188C10.962 8.34766 11.9207 8.34766 13.838 8.34766H15.1207C17.0347 8.34766 17.9933 8.34766 18.7248 8.72188C19.369 9.05339 19.8928 9.58207 20.2213 10.2323C20.5921 10.9706 20.5921 11.9382 20.5921 13.87V15.1545C20.5921 17.0863 20.5921 18.0539 20.2213 18.7922C19.8928 19.4424 19.369 19.9711 18.7248 20.3026C17.9933 20.6768 17.0347 20.6768 15.1207 20.6768H13.8514C11.934 20.6768 10.9754 20.6768 10.2439 20.3026C9.60064 19.9709 9.07796 19.4421 8.75075 18.7922C8.37664 18.0539 8.37664 17.0863 8.37664 15.1545L8.36328 13.87Z"
			stroke="#7D828C"
			strokeWidth="2"
		/>
		<path
			d="M9.74738 16.6056C13.4739 16.6056 16.4948 13.5599 16.4948 9.8028C16.4948 6.04572 13.4739 3 9.74738 3C6.0209 3 3 6.04572 3 9.8028C3 13.5599 6.0209 16.6056 9.74738 16.6056Z"
			stroke="#7D828C"
			strokeWidth="2"
		/>
	</svg>
)

export default CircleAndSquare
