import * as React from "react"
import { SVGProps } from "react"

const CheckedMagnifyingGlassIcon = (props: SVGProps<SVGSVGElement>) => (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M20.9794 20.9999L16.3418 16.3623"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
		/>
		<path
			d="M6.73242 9.91269L10.0099 13.1904L17.9162 5.29565"
			stroke="#7D828C"
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18.5232 10.4742C18.6425 13.7613 16.6441 16.8882 13.3816 18.0532C9.35 19.4929 4.9147 17.3917 3.47505 13.3601C2.03541 9.32852 4.13659 4.89321 8.16817 3.45357C10.0699 2.77447 12.0615 2.88325 13.7861 3.61088"
			stroke="#7D828C"
			strokeWidth="2"
			strokeMiterlimit="10"
			strokeLinecap="round"
		/>
	</svg>
)

export default CheckedMagnifyingGlassIcon
