import React from "react"
import classNames from "classnames"

import CardFullWidth2 from "../../../components/_V3/CardFullWidth2"

import styles from "./styles.module.scss"

import { getData } from './data'

import { useLocalizationContext } from '../../../localization/useLocalizationContext'


interface Props {
	className?: string
}

export default function CardFullWidthList(props: Props) {
	const localizationContext = useLocalizationContext()

	return (
		<section className={classNames(styles.section, props.className)}>
			{getData(localizationContext.locale).map(card => (
				<CardFullWidth2 data={card} key={card.subheading} className={props.className} />
			))}
		</section>
	)
}
