import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styles from "./styles.module.scss"

const generateAltText = (name: string) => `Логотип клиента «${name}»`

export default {
	title: "С нами более 10 000 заведений ежедневно выполняют миллионы заказов",
	customers: [
		{
			name: "Бодрый день",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/bodry.png"}
					alt={generateAltText(name)}
					height={70}
					width={100}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Кофедэй",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/coffeday.png"}
					alt={generateAltText(name)}
					height={70}
					width={80}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Майямми",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/yummy.png"}
					alt={generateAltText(name)}
					height={70}
					width={100}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Шаурма",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/shaurma.png"}
					alt={generateAltText(name)}
					height={70}
					width={134}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Добро Кофе",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/dobro.png"}
					alt={generateAltText(name)}
					height={70}
					width={55}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "М",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/m.png"}
					alt={generateAltText(name)}
					height={70}
					width={64}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Кофе Трдельники",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/cofe-i-tridelniki.png"}
					alt={generateAltText(name)}
					height={70}
					width={134}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Сулстанция",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/sylstancia.png"}
					alt={generateAltText(name)}
					height={70}
					width={134}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Триманго",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/trimango.png"}
					alt={generateAltText(name)}
					height={70}
					width={60}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Fast Pizza",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/fast-pizza.png"}
					alt={generateAltText(name)}
					height={70}
					width={134}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
		{
			name: "Металлург Магнитогорск",
			logo: (name: string) => (
				<StaticImage
					className={styles.logo}
					src={"./assets/metallurg.png"}
					alt={generateAltText(name)}
					height={70}
					width={95}
					layout={"fixed"}
					objectFit={"contain"}
					placeholder={"blurred"}
					quality={90}
				/>
			),
		},
	],
}
